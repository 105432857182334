/** @format */

import * as React from 'react'
import styled from 'fe-styled-components'
import { useTheme } from 'fe-hoc-theme'
import PropTypes from 'fe-prop-types'

const Svg = useTheme(styled.svg`
  &&& {
    display: inline-block;
    vertical-align: middle;
    box-sizing: unset;
  }
`)

export const SvgIcon = props => {
  let SvgData = props.glyph

  return (
    <Svg
      className={props.className}
      width={props.size || props.width}
      height={props.size || props.height}
      viewBox={props.viewBox}
      fill={props.fill}
      aria-labelledby={props.title ? props.titleId : null}
    >
      {props.title &&
        <title id={props.titleId}>{props.title}</title>
      }
      <SvgData highlight={props.highlight} />
    </Svg>
  )
}

SvgIcon.propTypes = {
  glyph: PropTypes.any.isRequired, // renderer that produce svg data
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
  highlight: PropTypes.string,
  titleId: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
}

SvgIcon.defaultProps = {
  fill: '#2f3638',
  width: 16,
  height: 16,
  size: null,
  viewBox: '0 0 20 20',
  highlight: '',
  titleId: null,
  className: '',
}
