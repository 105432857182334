// @ts-check
/* eslint-disable no-underscore-dangle */

/**
 * This is a copy of the code for getThemeValue in fe-lib-theme-provider.
 * Needed temporarily as a fallback in case the real theme provider is not
 * found/loaded.
 *
 * To be removed when fe-lib-theme-provider is moved in dashboard,
 * and guaranteed to be loaded.
 */

// @ts-ignore
import { interleave } from 'fe-lib-sequences'
import { light } from './themes/legacy/light'

const __deprecated__getIn = (cursor = [], obj) => {
  try {
    if (null == obj) return obj
    if (!cursor.length) return obj
    const [current, ...rest] = cursor
    return __deprecated__getIn(rest, obj[current])
  } catch (_err) {
    return undefined
  }
}

const __deprecated__getThemeValue = (parts, ...interops) => {
  const theme = light
  const path = interleave(parts, interops).join('')
  const cursor = path.split(/[/]/)
  return __deprecated__getIn(cursor, theme) || ''
}

const getIn = (getter, obj) => {
  try {
    return getter(obj)
  } catch (_err) {
    return undefined
  }
}

export const getThemeValue = (...args) => {
  const [firstArg] = args

  // Template literal usage is deprecated
  if (Array.isArray(firstArg)) {
    return __deprecated__getThemeValue(...args)
  }

  const getter = firstArg
  const theme = light
  return getIn(getter, theme) || ''
}
