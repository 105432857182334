// @ts-check

// @ts-ignore
import { getSingleton } from 'fe-lib-singleton'
import { getThemeValue } from './getThemeValueFallback'

// Fallback theme provider uses the internal legacy theme
const fallbackProvider = {
  getThemeValue,
  getIsBrand2020Active: () => false,
  subscribe: () => () => {},
}

export const getThemeProvider = () =>
  getSingleton('fe-lib-theme-provider') || fallbackProvider
