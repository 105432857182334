/** @format */

import * as React from 'react'

export const ArrowDown = () => (
  <polygon points="18.375 11.625 16.625 9.875 11.25 15.25 11.25 0 8.75 0 8.75 15.25 3.375 9.875 1.625 11.625 10 20" />
)
export const ArrowLeft = () => (
  <polygon points="8.375 18.375 10.125 16.625 4.75 11.25 20 11.25 20 8.75 4.75 8.75 10.125 3.375 8.375 1.625 0 10" />
)
export const ArrowRight = () => (
  <polygon points="11.625 1.625 9.875 3.375 15.25 8.75 0 8.75 0 11.25 15.25 11.25 9.875 16.625 11.625 18.375 20 10" />
)
export const ArrowUp = () => (
  <polygon points="1.625 8.375 3.375 10.125 8.75 4.75 8.75 20 11.25 20 11.25 4.75 16.625 10.125 18.375 8.375 10 0" />
)
export const ArrowUpSmall = () => (
  <polygon points="15 14.25 10 9.25 5 14.25 3.25 12.5 10 5.75 16.75 12.5" />
)
export const ArrowRightSmall = () => (
  <polygon points="7.5 16.75 5.75 15 10.75 10 5.75 5 7.5 3.25 14.25 10" />
)
export const ArrowDownSmall = () => (
  <polygon points="10 14.25 3.25 7.5 5 5.75 10 10.75 15 5.75 16.75 7.5" />
)
export const ArrowLeftSmall = () => (
  <polygon points="12.5 16.75 5.75 10 12.5 3.25 14.25 5 9.25 10 14.25 15" />
)
export const ArrowBottomLeft = () => (
  <path d="M2.5,18.75 L12.5,18.75 L12.5,16.25 L5.5175,16.25 L18.38375,3.38375 L16.61625,1.61625 L3.75,14.4825 L3.75,7.5 L1.25,7.5 L1.25,17.5 C1.25,18.19 1.81,18.75 2.5,18.75 Z" />
)
export const ArrowBottomRight = () => (
  <path d="M17.5,18.75 L7.5,18.75 L7.5,16.25 L14.4825,16.25 L1.61625,3.38375 L3.38375,1.61625 L16.25,14.4825 L16.25,7.5 L18.75,7.5 L18.75,17.5 C18.75,18.19 18.19,18.75 17.5,18.75 Z" />
)
export const ArrowTopLeft = () => (
  <path d="M2.5,1.25 L12.5,1.25 L12.5,3.75 L5.5175,3.75 L18.38375,16.61625 L16.61625,18.38375 L3.75,5.5175 L3.75,12.5 L1.25,12.5 L1.25,2.5 C1.25,1.81 1.81,1.25 2.5,1.25 Z" />
)
export const ArrowTopRight = () => (
  <path d="M17.5,1.25 L7.5,1.25 L7.5,3.75 L14.4825,3.75 L1.61625,16.61625 L3.38375,18.38375 L16.25,5.5175 L16.25,12.5 L18.75,12.5 L18.75,2.5 C18.75,1.81 18.19,1.25 17.5,1.25 Z" />
)
export const ChevronDown = () => (
  <polygon points="10 15.75 0.375 6.125 2.125 4.25 10 12.125 17.875 4.25 19.625 6.125" />
)
export const ChevronLeft = () => (
  <polygon points="13.875 19.625 4.25 10 13.875 0.375 15.75 2.125 7.875 10 15.75 17.875" />
)
export const ChevronRight = () => (
  <polygon points="6.125 19.625 4.25 17.875 12.125 10 4.25 2.125 6.125 0.375 15.75 10" />
)
export const ChevronUp = () => (
  <polygon points="17.875 15.75 10 7.875 2.125 15.75 0.375 13.875 10 4.25 19.625 13.875" />
)
export const ChevronDoubleLeftSmall = () => (
  <path d="M9.37499988,16.75 L2.62499988,10 L9.37499988,3.25 L11.1249999,5 L6.12499988,10 L11.1249999,15 L9.37499988,16.75 Z M15.6249999,16.75 L8.87499988,10 L15.6249999,3.25 L17.3749999,5 L12.3749999,10 L17.3749999,15 L15.6249999,16.75 Z" />
)
export const ChevronDoubleRightSmall = () => (
  <path d="M4.37499988,16.75 L2.62499988,15 L7.62499988,10 L2.62499988,5 L4.37499988,3.25 L11.1249999,10 L4.37499988,16.75 Z M10.6249999,16.75 L8.87499988,15 L13.8749999,10 L8.87499988,5 L10.6249999,3.25 L17.3749999,10 L10.6249999,16.75 Z" />
)
export const TriangleUpSmall = () => (
  <polygon points="16.25 13.75 10 6.25 3.75 13.75" />
)
export const TriangleRightSmall = () => (
  <polygon points="6.25 16.25 13.75 10 6.25 3.75" />
)
export const TriangleDownSmall = () => (
  <polygon points="3.75 6.25 10 13.75 16.25 6.25" />
)
export const TriangleLeftSmall = () => (
  <polygon points="13.75 3.75 6.25 10 13.75 16.25" />
)
export const TriangleUpDownSmall = () => (
  <path d="M10,18 L4,12 L16,12 L10,18 Z M4,8 L10,2 L16,8 L4,8 Z" />
)
export const BlockArrowDown = () => (
  <path d="M0,0 L20,0 L20,5 L0,5 L0,0 Z M11.25,15.75 L15,12 L16.75,13.75 L10.875,19.625 C10.625,19.875 10.375,20 10,20 C9.625,20 9.375,19.875 9.125,19.625 L3.25,13.75 L5,12 L8.75,15.75 L8.75,7.5 L11.25,7.5 L11.25,15.75 Z" />
)
export const BlockArrowLeft = () => (
  <path d="M15,0 L20,0 L20,20 L15,20 L15,0 Z M8,5 L4.25,8.75 L12.5,8.75 L12.5,11.25 L4.25,11.25 L8,15 L6.25,16.75 L0.375,10.875 C-0.125,10.375 -0.125,9.625 0.375,9.125 L6.25,3.25 L8,5 Z" />
)
export const BlockArrowRight = () => (
  <path d="M0,0 L5,0 L5,20 L0,20 L0,0 Z M13.75,3.25 L19.625,9.125 C20.125,9.625 20.125,10.375 19.625,10.875 L13.75,16.75 L12,15 L15.75,11.25 L7.5,11.25 L7.5,8.75 L15.75,8.75 L12,5 L13.75,3.25 Z" />
)
export const BlockArrowUp = () => (
  <path d="M0,15 L20,15 L20,20 L0,20 L0,15 Z M8.75,4.25 L5,8 L3.25,6.25 L9.125,0.375 C9.625,-0.125 10.375,-0.125 10.875,0.375 L16.75,6.25 L15,8 L11.25,4.25 L11.25,12.5 L8.75,12.5 L8.75,4.25 Z" />
)
export const ArrowCross = () => (
  <polygon points="16.25 8.75 11.25 8.75 11.25 3.75 13.75 3.75 10 0 6.25 3.75 8.75 3.75 8.75 8.75 3.75 8.75 3.75 6.25 0 10 3.75 13.75 3.75 11.25 8.75 11.25 8.75 16.25 6.25 16.25 10 20 13.75 16.25 11.25 16.25 11.25 11.25 16.25 11.25 16.25 13.75 20 10 16.25 6.25" />
)
export const BoxArrowTopRight = () => (
  <path d="M0,1.25 L0,18.75 C0,19.5 0.5,20 1.25,20 L18.75,20 C19.5,20 20,19.5 20,18.75 L20,1.25 C20,0.5 19.5,0 18.75,0 L1.25,0 C0.5,0 0,0.5 0,1.25 Z M16.25,10 L14,7.75 L8.375,13.375 L6.625,11.625 L12.25,6 L10,3.75 L16.25,3.75 L16.25,10 Z" />
)
export const BoxArrowTopRightOutline = () => (
  <path d="M16.25,20 L1.25,20 C0.56,20 0,19.44 0,18.75 L0,3.75 C0,3.06 0.56,2.5 1.25,2.5 L8.75,2.5 L8.75,5 L2.5,5 L2.5,17.5 L15,17.5 L15,11.25 L17.5,11.25 L17.5,18.75 C17.5,19.44 16.94,20 16.25,20 Z M20,0 L20,7.5 L17.13375,4.63375 L9.295,12.4725 L7.5275,10.705 L15.36625,2.86625 L12.5,0 L20,0 Z" />
)
export const BoxArrowRightOutline = () => (
  <path d="M13.75,17.5 L13.75,20 L1.25,20 C0.56,20 0,19.44 0,18.75 L0,1.25 C0,0.56 0.56,0 1.25,0 L13.75,0 L13.75,2.5 L2.5,2.5 L2.5,17.5 L13.75,17.5 Z M12.5,15.5175 L10.7325,13.75 L13.2325,11.25 L5,11.25 L5,8.75 L13.2325,8.75 L10.7325,6.25 L12.5,4.4825 L18.0175,10 L12.5,15.5175 Z" />
)
export const ArrowRoundCounterClockwise = () => (
  <path d="M10.875,0.875 L11.75,8.875 L14.375,6.25 C16.75,8.625 16.75,12.625 14.375,15 C13.25,16.25 11.625,16.875 10,16.875 C8.375,16.875 6.75,16.25 5.625,15 C3.25,12.625 3.25,8.625 5.625,6.25 C6.375,5.5 7.375,4.875 8.5,4.625 L7.75,2.25 C6.25,2.625 4.875,3.375 3.75,4.5 C0.375,7.875 0.375,13.375 3.75,16.875 C5.375,18.5 7.625,19.375 9.875,19.375 C12.25,19.375 14.375,18.5 16,16.875 C19.375,13.5 19.375,8 16,4.5 L18.75,1.75 L10.875,0.875 Z" />
)
export const ArrowRoundClockwise = () => (
  <path d="M10,17.5 C6.25,17.5 3.125,14.375 3.125,10.625 C3.125,6.875 6.25,3.75 10,3.75 C11.5,3.75 12.75,4.25 14,5 L10.625,5 L10.625,7.5 L18.125,7.5 L18.125,0 L15.625,0 L15.625,3.125 C14,1.875 12.125,1.25 10,1.25 C4.875,1.25 0.625,5.5 0.625,10.625 C0.625,15.75 4.875,20 10,20 C15.125,20 19.375,15.75 19.375,10.625 L16.875,10.625 C16.875,14.375 13.75,17.5 10,17.5 Z" />
)
export const ArrowDownToLeft= () => (
  <path d="M12,12.7819549 L4.53333333,12.7819549 L9.86666667,18.128655 L8,20 L0.4,12.3809524 C-0.133333333,11.8462824 -0.133333333,11.0442774 0.4,10.5096074 L8,2.8905597 L9.86666667,4.7619048 L4.53333333,10.1086049 L12,10.1086049 C14.9333333,10.1086049 17.3333333,7.7025898 17.3333333,4.7619048 L17.3333333,-1.77635684e-15 L20,-1.77635684e-15 L20,4.7619048 C20,9.1729323 16.4,12.7819549 12,12.7819549 Z" />
)
