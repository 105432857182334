export const family_primary = '"Open Sans", "Helvetica Neue", Helvetica, Arial'
export const family_secondary = 'Arial, Helvetica, sans-serif'
export const family_serif = 'Georgia, "Times New Roman", Times, serif'
export const family_monospaced = '"Courier New", Courier, monospace'

export const weight_normal = '400'
export const weight_semiBold = '600'
export const weight_bold = '700'

export const size_xlarge = '36px'
export const size_h1 = '30px'
export const size_h2 = '24px'
export const size_h3 = '24px'
export const size_h4 = '16px'
export const size_body = '14px'
export const size_small = '12px'
export const size_tiny = '10px'

export const typography = {
  fontFamily: {
    primary: family_primary,
    secondary: family_secondary,
    serif: family_serif,
    monospaced: family_monospaced,
  },

  weight: {
    normal: weight_normal,
    semi: weight_semiBold,
    bold: weight_bold,
  },

  size: {
    h1: size_h1,
    h2: size_h2,
    h3: size_h3,
    h4: size_h4,
    body: size_body,
    copy: size_body,
    paragraph: size_body,
    small: size_small,
    tiny: size_tiny,
    button: size_body,
    xlarge: size_xlarge,
  }
}
