/** @format */

import * as React from 'react'
import PropTypes from 'fe-prop-types'
import styled from 'fe-styled-components'
import { withHsTheme } from 'fe-lib-theme'
import { BouncingBars } from 'fe-comp-loader'

export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'
export const STANDARD = 'standard'
export const CTA = 'cta'
export const ICON = 'icon'
export const SIZE_24 = '24'
export const SIZE_32 = '32'
export const SIZE_44 = '44'
export const SIZE_60 = '60'
export const HTML_TYPE_BUTTON = 'button'
export const HTML_TYPE_SUBMIT = 'submit'
export const HTML_TYPE_RESET = 'reset'

const BOX_SHADOW_SIZE = '0 0 4px 2px'

const BtnStyle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  min-width: ${p => (p.styleType === 'icon' ? `${p.height}px` : '80px')};
  max-width: 500px;
  width: ${p =>
    p.width.toString().match(/\D/) === null ? `${p.width}px` : p.width};
  height: ${p =>
    p.height.toString().match(/\D/) === null ? `${p.height}px` : p.height};
  padding: 0 ${p => (p.styleType === 'icon' ? '0' : '24px')};
  vertical-align: middle;

  font-family: ${p => p.$theme`typography/fontFamily/primary`};
  font-size: ${p => p.$theme`typography/size/button`};
  font-weight: ${p => p.$theme`typography/weight/semi`};
  line-height: ${p => (p.styleType === 'icon' ? '0' : '1.2')};

  text-align: center;
  text-decoration: none;
  text-shadow: none;

  white-space: nowrap;
  user-select: none;

  transition: background-color 0.15s ease-out, border-color 0.1s ease-out,
    box-shadow 0.1s ease-out;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${p =>
    !p.isLoading ? p.$theme`colors/button/${p.styleType}/copy` : 'transparent'};
  background-color: ${p => p.$theme`colors/button/${p.styleType}/fill`};
  border: 1px solid ${p => p.$theme`colors/button/${p.styleType}/keyline`};
  border-radius: ${p => (p.styleType === ICON ? '50%' : '3px')};

  &:hover:not([disabled]):not(:active) {
    background-color: ${p => p.$theme`colors/button/${p.styleType}/hover/fill`};
    border-color: ${p => p.$theme`colors/button/${p.styleType}/hover/keyline`};
    color: ${p =>
      !p.isLoading
        ? p.$theme`colors/button/${p.styleType}/hover/copy`
        : 'transparent'};
    cursor: pointer;
  }
  &:focus {
    background-color: ${p => p.$theme`colors/button/${p.styleType}/focus/fill`};
    border-color: ${p => p.$theme`colors/button/${p.styleType}/focus/keyline`};
    box-shadow: ${BOX_SHADOW_SIZE}
      ${p => p.$theme`colors/button/${p.styleType}/focus/keyline`};
    color: ${p =>
      !p.isLoading
        ? p.$theme`colors/button/${p.styleType}/focus/copy`
        : 'transparent'};
  }
  &:active {
    background-color: ${p =>
      p.$theme`colors/button/${p.styleType}/active/fill`};
    border-color: ${p => p.$theme`colors/button/${p.styleType}/active/keyline`};
    box-shadow: ${BOX_SHADOW_SIZE}
      ${p => p.$theme`colors/button/${p.styleType}/active/keyline`};
    color: ${p =>
      !p.isLoading
        ? p.$theme`colors/button/${p.styleType}/active/copy`
        : 'transparent'};
  }
  &[disabled] {
    background-color: ${p =>
      p.$theme`colors/button/${p.styleType}/disabled/fill`};
    border-color: ${p =>
      p.$theme`colors/button/${p.styleType}/disabled/keyline`};
    color: ${p =>
      !p.isLoading
        ? p.$theme`colors/button/${p.styleType}/disabled/copy`
        : 'transparent'};
  }
`
/* May be deprecated in V4 of styled components. Please change to use the new "as" prop once we update to V4
See https://www.styled-components.com/docs/api#as-polymorphic-prop for reference */
const BtnLinkStyle = BtnStyle.extend`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`.withComponent('a')

const Btn = withHsTheme(BtnStyle)
const BtnLink = withHsTheme(BtnLinkStyle)

export const Button = withHsTheme(props => {
  const RenderedBtn = props.href ? BtnLink : Btn

  return (
    <RenderedBtn {...props} styleType={props.type} type={props.htmlType}>
      {props.isLoading ? (
        <BouncingBars
          size={Math.floor(props.height / 2) + 4}
          fill={props.$theme`colors/button/${props.type}/copy`}
        />
      ) : props.children}
    </RenderedBtn>
  )
})

Button.propTypes = {
  width: PropTypes.string,
  height: PropTypes.oneOf([SIZE_24, SIZE_32, SIZE_44, SIZE_60]),
  type: PropTypes.oneOf([PRIMARY, SECONDARY, STANDARD, CTA, ICON]),
  href: PropTypes.string,
  htmlType: PropTypes.oneOf([
    HTML_TYPE_BUTTON,
    HTML_TYPE_SUBMIT,
    HTML_TYPE_RESET,
  ]),
}

Button.defaultProps = {
  width: 'auto',
  height: SIZE_44,
  isLoading: false,
  type: STANDARD,
  htmlType: HTML_TYPE_BUTTON,
}
