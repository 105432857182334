import * as React from 'react'
import PropTypes from 'fe-prop-types'
import styled, { css } from 'fe-styled-components'
import { withHsTheme, getThemeValue } from 'fe-lib-theme'
import { BouncingBars } from 'fe-comp-loader'

export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'
export const STANDARD = 'standard'
export const CTA = 'cta'
export const ICON = 'icon'
export const SIZE_24 = '24'
export const SIZE_32 = '32'
export const SIZE_44 = '44'
export const SIZE_60 = '60'
export const HTML_TYPE_BUTTON = 'button'
export const HTML_TYPE_SUBMIT = 'submit'
export const HTML_TYPE_RESET = 'reset'

const BtnStyle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  min-width: 80px;
  max-width: 500px;
  width: ${p =>
    p.width.toString().match(/\D/) === null ? `${p.width}px` : p.width};
  height: ${p =>
    p.height.toString().match(/\D/) === null ? `${p.height}px` : p.height};
  padding: 0 24px;
  vertical-align: middle;

  font-family: ${() => getThemeValue(t => t.typography.fontFamily.primary)};
  font-size: ${() => getThemeValue(t => t.typography.label.size)};
  font-weight: ${() => getThemeValue(t => t.typography.label.weight)};
  line-height: ${() => getThemeValue(t => t.typography.label.lineHeight)};

  text-align: center;
  text-decoration: none;
  text-shadow: none;

  white-space: nowrap;
  user-select: none;

  transition: background-color 0.15s ease-out, border-color 0.1s ease-out;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${p =>
    !p.isLoading ? getThemeValue(t => t.colors.button.text) : 'transparent'};
  background-color: ${() => getThemeValue(t => t.colors.button.background)};
  border-radius: 2px;

  &:hover:not([disabled]):not(:active) {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.hoverBackground)};
    cursor: pointer;
  }
  &:focus {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.focusBackground)};
    border: 3px solid ${() => getThemeValue(t => t.colors.focusBorder)};
  }
  &:active {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.activeBackground)};
  }
  &[disabled] {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.disabledBackground)};
    color: ${p =>
      !p.isLoading
        ? getThemeValue(t => t.colors.button.disabledText)
        : 'transparent'};
  }

  ${p => styleModifiers[p.styleType]}
`

const iconBtnStyle = css`
  min-width: ${p => `${p.height}px`};
  padding: 0 0;
  line-height: 0;
  border-radius: 50%;
`

const secondaryBtnStyle = css`
  background-color: ${() =>
    getThemeValue(t => t.colors.button.secondary.background)};
  border: 1px solid
    ${() => getThemeValue(t => t.colors.button.secondary.border)};
`

const ctaBtnStyle = css`
  background-color: ${() => getThemeValue(t => t.colors.button.cta.background)};
  &:hover:not([disabled]):not(:active) {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.cta.hoverBackground)};
  }
  &:focus {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.cta.focusBackground)};
  }
  &:active {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.cta.activeBackground)};
  }
  &[disabled] {
    background-color: ${() =>
      getThemeValue(t => t.colors.button.cta.disabledBackground)};
  }
`

const styleModifiers = {
  [ICON]: iconBtnStyle,
  [SECONDARY]: secondaryBtnStyle,
  [CTA]: ctaBtnStyle,
  [PRIMARY]: '',
  [STANDARD]: '',
}

/* May be deprecated in V4 of styled components. Please change to use the new "as" prop once we update to V4
See https://www.styled-components.com/docs/api#as-polymorphic-prop for reference */
const BtnLinkStyle = BtnStyle.extend`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`.withComponent('a')

const Btn = withHsTheme(BtnStyle)
const BtnLink = withHsTheme(BtnLinkStyle)

export const Button = withHsTheme(props => {
  const RenderedBtn = props.href ? BtnLink : Btn

  return (
    <RenderedBtn {...props} styleType={props.type} type={props.htmlType}>
      {props.isLoading ? (
        <BouncingBars
          size={Math.floor(props.height / 2) + 4}
          fill={geThemeValue(t => t.colors.button.text)}
        />
      ) : (
        props.children
      )}
    </RenderedBtn>
  )
})

Button.propTypes = {
  width: PropTypes.string,
  height: PropTypes.oneOf([SIZE_24, SIZE_32, SIZE_44, SIZE_60]),
  type: PropTypes.oneOf([PRIMARY, SECONDARY, STANDARD, CTA, ICON]),
  href: PropTypes.string,
  htmlType: PropTypes.oneOf([
    HTML_TYPE_BUTTON,
    HTML_TYPE_SUBMIT,
    HTML_TYPE_RESET,
  ]),
}

Button.defaultProps = {
  width: 'auto',
  height: SIZE_44,
  isLoading: false,
  type: STANDARD,
  htmlType: HTML_TYPE_BUTTON,
}
