/** @format */

import * as React from 'react'

export const Archive = () => (
  <path d="M18.75,5 L1.25,5 C0.5,5 0,5.5 0,6.25 L0,18.75 C0,19.5 0.5,20 1.25,20 L18.75,20 C19.5,20 20,19.5 20,18.75 L20,6.25 C20,5.5 19.5,5 18.75,5 Z M15,13.75 L5,13.75 L5,8.75 L7.5,8.75 L7.5,11.25 L12.5,11.25 L12.5,8.75 L15,8.75 L15,13.75 Z M3.75,2.5 C3,2.5 2.5,2 2.5,1.25 C2.5,0.5 3,0 3.75,0 L16.25,0 C17,0 17.5,0.5 17.5,1.25 C17.5,2 17,2.5 16.25,2.5 L3.75,2.5 Z" />
)
export const At = () => (
  <path d="M18.7517647,12.5682353 C18.3003016,14.2109713 16.838671,15.3734266 15.1364706,15.4435294 C13.9954246,15.4982464 12.9690162,14.7543447 12.6658824,13.6529412 C11.8995227,14.7681054 10.6354444,15.4370648 9.28235294,15.4435294 C8.17163496,15.4990691 7.09378243,15.0575807 6.34117647,14.2388235 C5.58744056,13.3251181 5.2044933,12.1615798 5.26823529,10.9788235 C5.20872058,9.56008886 5.72163439,8.17691717 6.69176471,7.14 C8.22117647,5.55647059 10.2682353,5.50352941 12.4482353,5.82 C13.089086,5.90771592 13.7209477,6.05180401 14.3364706,6.25058824 C14.3141176,6.71411765 14.0835294,11.4270588 14.0835294,11.8635294 C14.0835294,13.0886275 14.4815686,13.7007843 15.2776471,13.7 C15.8815686,13.7 16.3615686,13.3078431 16.7176471,12.5235294 C17.5187692,10.4334074 17.4212835,8.10524507 16.4482353,6.08941176 C15.2918932,4.06568268 13.0906211,2.86983428 10.7635294,3.00117647 C7.86321506,2.80867269 5.11916403,4.33329893 3.75058824,6.89764706 C3.09223992,8.25252656 2.76560362,9.74456891 2.79764706,11.2505882 C2.79764706,15.5423529 5.09411765,18.16 9.68470588,18.16 C11.4972262,18.1374033 13.2907474,17.7874674 14.9788235,17.1270588 L14.9788235,19.0094118 C13.3113041,19.6805955 11.5280347,20.0171822 9.73058824,20 C4.23529412,20 0.731764706,16.9647059 0.731764706,11.3235294 C0.697795875,9.4652243 1.14049831,7.62920406 2.01764706,5.99058824 C3.78518649,2.87000432 7.15752928,1.00871127 10.74,1.17647059 C13.7707733,1.01438049 16.6416487,2.54563485 18.1952941,5.15294118 C19.400415,7.44077556 19.6019363,10.1261643 18.7517647,12.5682353 Z M7.46941176,11.0247059 C7.46941176,12.8082353 8.16941176,13.7 9.56941176,13.7 C11.0462745,13.7 11.8537255,12.5788235 11.9917647,10.3364706 L12.1294118,7.59294118 C10.8066882,7.08565681 9.30987166,7.39024958 8.29058824,8.37411765 C7.70841346,9.13086972 7.41703934,10.0713653 7.46941176,11.0247059 L7.46941176,11.0247059 Z" />
)
export const Attach = () => (
  <path d="M11.8823529,16.2352941 C10,18.1176471 7.05882353,18.1176471 5.17647059,16.2352941 C3.29411765,14.3529412 3.17647059,11.5294118 5.05882353,9.76470588 L10.3529412,4.47058824 C11.5294118,3.29411765 13.4117647,3.29411765 14.4705882,4.47058824 C15.6470588,5.64705882 15.6470588,7.52941176 14.4705882,8.58823529 L10.3529412,12.8235294 C9.88235294,13.2941176 9.17647059,13.2941176 8.70588235,12.8235294 C8.23529412,12.3529412 8.23529412,11.6470588 8.70588235,11.1764706 L12,7.88235294 L10.2352941,6.23529412 L7.05882353,9.41176471 C5.64705882,10.8235294 5.64705882,13.0588235 7.05882353,14.3529412 C8.47058824,15.6470588 10.7058824,15.7647059 12,14.3529412 L16.2352941,10.1176471 C18.3529412,8 18.3529412,4.70588235 16.2352941,2.58823529 C14.1176471,0.470588235 10.8235294,0.470588235 8.70588235,2.58823529 L3.41176471,8.11764706 C0.705882353,10.8235294 0.823529412,15.1764706 3.52941176,17.8823529 C4.94117647,19.2941176 6.70588235,20 8.58823529,20 C10.4705882,20 12.2352941,19.2941176 13.5294118,17.8823529 L18.5882353,12.8235294 L16.9411765,11.1764706 L11.8823529,16.2352941 Z" />
)
export const Tag = () => (
  <path d="M19.625,10.375 L9.625,0.375 C9.375,0.125 9.125,0 8.75,0 L1.25,0 C0.5,0 0,0.5 0,1.25 L0,8.75 C0,9.125 0.125,9.375 0.375,9.625 L10.375,19.625 C10.625,19.875 10.875,20 11.25,20 C11.625,20 11.875,19.875 12.125,19.625 L19.625,12.125 C20.125,11.625 20.125,10.875 19.625,10.375 Z M5,6.25 C4.25,6.25 3.75,5.75 3.75,5 C3.75,4.25 4.25,3.75 5,3.75 C5.75,3.75 6.25,4.25 6.25,5 C6.25,5.75 5.75,6.25 5,6.25 Z" />
)
export const TagPlus = (props) => (
  <g>
    <path d="M15.539896,16.210104 L12.125,19.625 C11.875,19.875 11.625,20 11.25,20 C10.875,20 10.625,19.875 10.375,19.625 L0.375,9.625 C0.125,9.375 0,9.125 0,8.75 L0,1.25 C0,0.5 0.5,0 1.25,0 L8.75,0 C9.125,0 9.375,0.125 9.625,0.375 L13.5918164,4.34181644 C11.4692713,5.34071267 10,7.49877299 10,10 C10,13.2116755 12.4224737,15.8575519 15.539896,16.210104 Z M5,6.25 C5.75,6.25 6.25,5.75 6.25,5 C6.25,4.25 5.75,3.75 5,3.75 C4.25,3.75 3.75,4.25 3.75,5 C3.75,5.75 4.25,6.25 5,6.25 Z" />
    <path fill={props.highlight || null} d="M17.5,6.25 L15,6.25 L15,8.75 L12.5,8.75 L12.5,11.25 L15,11.25 L15,13.75 L17.5,13.75 L17.5,11.25 L20,11.25 L20,8.75 L17.5,8.75 L17.5,6.25 Z" />
  </g>
)
export const Like = () => (
  <path d="M16.6008316,7.5 C17.5987526,7.5 18.5966736,7.875 19.2203742,8.75 C19.8440748,9.375 20.0935551,10.375 19.968815,11.375 L18.970894,17.125 C18.7214137,18.75 17.3492723,20 15.7276507,20 L5,20 L5,10 C5.12474012,10 7.99376299,0.625 7.99376299,0.625 C7.99376299,0.25 8.36798337,0 8.74220374,0 C10.2390852,0 11.2370062,0.625 11.2370062,2.5 L11.2370062,7.5 L16.6008316,7.5 Z M0,10 L2.5,10 L2.5,20 L0,20 L0,10 Z" />
)
export const Heart = () => (
  <path d="M18.3521875,2.89734375 C16.1559375,0.70109375 12.5934375,0.70109375 10.3971875,2.89734375 C10.2459375,3.04859375 10.1296875,3.22109375 9.9996875,3.38359375 C9.8696875,3.22109375 9.7534375,3.04859375 9.6021875,2.89734375 C7.4059375,0.70109375 3.8434375,0.70109375 1.6471875,2.89734375 C-0.5490625,5.09359375 -0.5490625,8.65609375 1.6471875,10.8523437 L9.9996875,18.7498438 L18.3521875,10.8523437 C20.5484375,8.65609375 20.5484375,5.09359375 18.3521875,2.89734375 Z" />
)
export const HeartOutline = () => (
  <path d="M9.25,19.3663476 C8.25,18.4913476 0,11.6163476 0,6.36634758 C0,2.99134758 2.75,0.366347577 6,0.366347577 C7.5,0.366347577 8.875,0.991347577 10,1.86634758 C11.125,0.866347577 12.5,0.366347577 14,0.366347577 C17.375,0.366347577 20,3.11634758 20,6.36634758 C20,11.6163476 11.75,18.4913476 10.75,19.2413476 C10.375,19.7413476 9.625,19.7413476 9.25,19.3663476 Z M6,2.86634758 C4.125,2.86634758 2.5,4.49134758 2.5,6.36634758 C2.5,9.11634758 6.875,13.8663476 10,16.6163476 C12.625,14.2413476 17.5,9.36634758 17.5,6.36634758 C17.5,4.36634758 15.875,2.86634758 14,2.86634758 C12.875,2.86634758 11.75,3.49134758 11.125,4.49134758 C10.625,5.24134758 9.5,5.24134758 9,4.49134758 C8.25,3.49134758 7.25,2.86634758 6,2.86634758 Z" />
)
export const Star = () => (
  <path d="M18.9298284,6.76853749 L13.5348284,5.98478749 L11.1210784,1.09603749 C10.6985784,0.24228749 9.30107839,0.24228749 8.87857839,1.09603749 L6.46607839,5.98478749 L1.06982839,6.76853749 C0.0485783934,6.91728749 -0.365171607,8.17728749 0.377328393,8.90228749 L4.28232839,12.7072875 L3.36107839,18.0810375 C3.18732839,19.0985375 4.25732839,19.8797875 5.17482839,19.3985375 L9.99982839,16.8622875 L14.8260784,19.3997875 C15.7360784,19.8760375 16.8148284,19.1072875 16.6398284,18.0822875 L15.7185784,12.7085375 L19.6235784,8.90353749 C20.3648284,8.17728749 19.9510784,6.91728749 18.9298284,6.76853749 Z" />
)
export const StarOutline = () => (
  <path d="M14.825,19.4275 L10,16.89125 L5.175,19.4275 C4.26625,19.91125 3.185,19.13375 3.36125,18.11 L4.2825,12.73625 L0.3775,8.93125 C-0.365,8.20625 0.04875,6.9475 1.07125,6.79875 L6.46625,6.015 L8.88,1.125 C9.3,0.27125 10.70125,0.27125 11.12125,1.125 L13.535,6.01375 L18.93,6.7975 C19.9525,6.94625 20.365,8.205 19.62375,8.93 L15.71875,12.73625 L16.64,18.11 C16.81625,19.13875 15.7325,19.905 14.825,19.4275 Z M10.5825,14.3725 L13.7475,16.03625 L13.14375,12.51125 C13.07375,12.10625 13.20875,11.6925 13.5025,11.405 L16.06375,8.9075 L12.525,8.39375 C12.11625,8.335 11.765,8.07875 11.58375,7.71 L10,4.5025 L8.41625,7.71 C8.23375,8.07875 7.8825,8.335 7.475,8.39375 L3.93625,8.9075 L6.4975,11.405 C6.79125,11.69125 6.92625,12.10625 6.85625,12.51125 L6.2525,16.03625 L9.4175,14.3725 C9.7825,14.18 10.2175,14.18 10.5825,14.3725 Z" />
)
export const Reply = () => (
  <path d="M10,6.25 C16.5,6.25 20,11 20,18.75 C18.125,15.75 16.625,13.75 10,13.75 L10,18.75 L0,10 L10,1.25 L10,6.25 Z" />
)
export const ReplyAll = () => (
  <path d="M12.13375,7.5 C16.13375,7.5 19.63375,10.625 19.63375,16.25 C18.25875,14.125 16.63375,12.5 12.13375,12.5 L12.13375,16.25 L5.88375,10 L12.13375,3.75 L12.13375,7.5 Z M7.13375,13.2325 L7.13375,16.7675 L0.36625,10 L7.13375,3.2325 L7.13375,6.7675 L3.90125,10 L7.13375,13.2325 Z" />
)
export const ArrowsLoop = () => (
  <path d="M2.5,6.25 L2.5,8.75 L0,8.75 L0,5 C0,4.25 0.5,3.75 1.25,3.75 L15.75,3.75 L14.125,2.125 C13.625,1.625 13.625,0.875 14.125,0.375 C14.625,-0.125 15.375,-0.125 15.875,0.375 L19.625,4.125 C20.125,4.625 20.125,5.375 19.625,5.875 L15.875,9.625 C15.625,9.875 15.375,10 15,10 C14.625,10 14.375,9.875 14.125,9.625 C13.625,9.125 13.625,8.375 14.125,7.875 L15.75,6.25 L2.5,6.25 Z M17.5,13.75 L17.5,11.25 L20,11.25 L20,15 C20,15.75 19.5,16.25 18.75,16.25 L4.25,16.25 L5.875,17.875 C6.375,18.375 6.375,19.125 5.875,19.625 C5.625,19.875 5.375,20 5,20 C4.625,20 4.375,19.875 4.125,19.625 L0.375,15.875 C-0.125,15.375 -0.125,14.625 0.375,14.125 L4.125,10.375 C4.625,9.875 5.375,9.875 5.875,10.375 C6.375,10.875 6.375,11.625 5.875,12.125 L4.25,13.75 L17.5,13.75 Z" />
)
export const Merge = () => (
  <path d="M16.7675,6.25 L15,8.0175 L11.25,4.2675 L11.25,14.2675 L5.88375,19.63375 L4.11625,17.86625 L8.75,13.2325 L8.75,4.2675 L5,8.0175 L3.2325,6.25 L9.11625,0.36625 C9.605,-0.1225 10.395,-0.1225 10.88375,0.36625 L16.7675,6.25 Z M11.7677879,17.2848301 L13.5355379,15.5170801 L15.8839938,17.865536 L14.1162438,19.633286 L11.7677879,17.2848301 Z" />
)
export const Group = () => (
  <path d="M10,7.5 C7.875,7.5 6.25,5.875 6.25,3.75 C6.25,1.625 7.875,0 10,0 C12.125,0 13.75,1.625 13.75,3.75 C13.75,5.875 12.125,7.5 10,7.5 Z M12.5,20 L7.5,20 L7.5,15 L5,12.5 L5,8.75 L15,8.75 L15,12.5 L12.5,15 L12.5,20 Z M2.5,6.25 C1.125,6.25 0,5.125 0,3.75 C0,2.375 1.125,1.25 2.5,1.25 C3.875,1.25 5,2.375 5,3.75 C5,5.125 3.875,6.25 2.5,6.25 Z M3.75,13 L5,14.25 L5,17.5 L1.25,17.5 L1.25,12.5 L0,11.25 L0,7.5 L3.75,7.5 L3.75,13 Z M17.5,6.25 C16.125,6.25 15,5.125 15,3.75 C15,2.375 16.125,1.25 17.5,1.25 C18.875,1.25 20,2.375 20,3.75 C20,5.125 18.875,6.25 17.5,6.25 Z M16.25,13 L16.25,7.5 L20,7.5 L20,11.25 L18.75,12.5 L18.75,17.5 L15,17.5 L15,14.25 L16.25,13 Z" />
)
export const Send = () => (
  <path d="M19.63375,0.36625 C19.2925,0.02625 18.78375,-0.09 18.33,0.07375 L0.83,6.32375 C0.35875,6.49125 0.0325,6.925 0.0025,7.42375 C-0.0275,7.92375 0.2425,8.3925 0.69125,8.6175 L6.42375,11.4825 L13.75,6.25 L8.51625,13.5775 L11.38125,19.31 C11.595,19.73375 12.02875,20 12.5,20 C12.52625,20 12.55125,19.99875 12.57625,19.9975 C13.07625,19.9675 13.51,19.6425 13.67875,19.17 L19.92875,1.67 C20.09,1.21625 19.975,0.7075 19.63375,0.36625 Z" />
)
export const StatusUser = () => (
  <path d="M18.75,1.25 C19.5,1.25 20,1.75 20,2.5 L20,18.75 C20,19.5 19.5,20 18.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,2.5 C0,1.75 0.5,1.25 1.25,1.25 L5,1.25 L5,3.75 L2.5,3.75 L2.5,17.5 L17.5,17.5 L17.5,3.75 L15,3.75 L15,1.25 L18.75,1.25 Z M6.25,0 L13.75,0 L13.75,5 L6.25,5 L6.25,0 Z M6.25,12.5 L13.75,12.5 L13.75,15 L6.25,15 L6.25,12.5 Z M10,11.25 C8.61928813,11.25 7.5,10.1307119 7.5,8.75 C7.5,7.36928813 8.61928813,6.25 10,6.25 C11.3807119,6.25 12.5,7.36928813 12.5,8.75 C12.5,10.1307119 11.3807119,11.25 10,11.25 Z" />
)
export const StatusPlus = () => (
  <path d="M18.75,1.25 C19.5,1.25 20,1.75 20,2.5 L20,18.75 C20,19.5 19.5,20 18.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,2.5 C0,1.75 0.5,1.25 1.25,1.25 L5,1.25 L5,3.75 L2.5,3.75 L2.5,17.5 L17.5,17.5 L17.5,3.75 L15,3.75 L15,1.25 L18.75,1.25 Z M6.25,0 L13.75,0 L13.75,5 L6.25,5 L6.25,0 Z M13.75,10 L13.75,12.5 L11.25,12.5 L11.25,15 L8.75,15 L8.75,12.5 L6.25,12.5 L6.25,10 L8.75,10 L8.75,7.5 L11.25,7.5 L11.25,10 L13.75,10 Z" />
)
export const StatusResolve = () => (
  <path d="M18.75,1.25 C19.5,1.25 20,1.75 20,2.5 L20,18.75 C20,19.5 19.5,20 18.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,2.5 C0,1.75 0.5,1.25 1.25,1.25 L5,1.25 L5,3.75 L2.5,3.75 L2.5,17.5 L17.5,17.5 L17.5,3.75 L15,3.75 L15,1.25 L18.75,1.25 Z M6.25,0 L13.75,0 L13.75,5 L6.25,5 L6.25,0 Z M7.875,14.625 L5.375,12.125 C4.875,11.625 4.875,10.875 5.375,10.375 C5.875,9.875 6.625,9.875 7.125,10.375 L8.75,12 L12.875,7.875 C13.375,7.375 14.125,7.375 14.625,7.875 C15.125,8.375 15.125,9.125 14.625,9.625 L9.625,14.625 C9.125,15.125 8.5,15.25 7.875,14.625 Z" />
)
export const User = () => (
  <path d="M15.045,11.7825 C17.24625,12.5 18.75,14.13875 18.75,16.25 L18.75,20 L1.25,20 L1.25,16.25 C1.25,14.13875 2.75375,12.5 4.955,11.7825 C6.28875,13 8.05625,13.75 10,13.75 C11.94375,13.75 13.71125,13 15.045,11.7825 Z M10,0 C12.7575,0 15,2.2425 15,5 L15,6.25 C15,9.0075 12.7575,11.25 10,11.25 C7.2425,11.25 5,9.0075 5,6.25 L5,5 C5,2.2425 7.2425,0 10,0 Z" />
)
export const UserPlus = () => (
  <path d="M17.5,12.5 L17.5,15 L20,15 L20,17.5 L17.5,17.5 L17.5,20 L15,20 L15,17.5 L12.5,17.5 L12.5,15 L15,15 L15,12.5 L17.5,12.5 Z M8.75,11.25 C6,11.25 3.75,9 3.75,6.25 L3.75,5 C3.75,2.25 6,0 8.75,0 C11.5,0 13.75,2.25 13.75,5 L13.75,6.25 C13.75,9 11.5,11.25 8.75,11.25 Z M6.25,13.75 L10,13.75 L10,20 L0,20 C0,16.5 2.75,13.75 6.25,13.75 Z" />
)
export const UserMinus = () => (
  <path d="M6.25,13.75 L10,13.75 L10,20 L0,20 C0,16.5 2.75,13.75 6.25,13.75 Z M8.75,11.25 C6,11.25 3.75,9 3.75,6.25 L3.75,5 C3.75,2.25 6,0 8.75,0 C11.5,0 13.75,2.25 13.75,5 L13.75,6.25 C13.75,9 11.5,11.25 8.75,11.25 Z M12.5,15 L20,15 L20,17.5 L12.5,17.5 L12.5,15 Z" />
)
export const Share = () => (
  <path d="M15,7.5 C17.0675,7.5 18.75,5.8175 18.75,3.75 C18.75,1.6825 17.0675,0 15,0 C12.9325,0 11.25,1.6825 11.25,3.75 C11.25,4.02875 11.28625,4.29875 11.34375,4.56125 L7.31875,7.07625 C6.67875,6.5675 5.88,6.25 5,6.25 C2.9325,6.25 1.25,7.9325 1.25,10 C1.25,12.0675 2.9325,13.75 5,13.75 C5.88,13.75 6.67875,13.4325 7.31875,12.92375 L11.34375,15.43875 C11.28625,15.70125 11.25,15.97125 11.25,16.25 C11.25,18.3175 12.9325,20 15,20 C17.0675,20 18.75,18.3175 18.75,16.25 C18.75,14.1825 17.0675,12.5 15,12.5 C14.12,12.5 13.32125,12.8175 12.68125,13.32625 L8.65625,10.81125 C8.71375,10.54875 8.75,10.27875 8.75,10 C8.75,9.72125 8.71375,9.45125 8.65625,9.18875 L12.68125,6.67375 C13.32125,7.1825 14.12,7.5 15,7.5 Z" />
)
export const Boost = () => (
  <g>
    <path d="M1.57370554,18.4544272 C1.66941234,18.5502626 1.81590108,18.5736686 1.93670554,18.5124272 C2.09470554,18.4324272 2.49770554,18.2464272 2.84770554,18.1514272 C2.91470554,18.3534272 3.01270554,18.5264272 3.04170554,18.5744272 C3.09770554,18.6694272 3.20070554,18.7274272 3.30970554,18.7274272 L3.33270554,18.7274272 C3.42870554,18.7194272 5.68470554,18.5454272 6.81170554,17.4184272 C7.06970554,17.1584272 7.29370554,16.8714272 7.47470554,16.5614272 C7.52836837,16.4682773 7.53082163,16.3541922 7.4812127,16.2588216 C7.43160376,16.1634509 7.33678328,16.0999647 7.22970554,16.0904272 C6.25970554,16.0124272 5.46970554,15.6754272 4.88370554,15.0874272 C4.31370554,14.5174272 3.97970554,13.7524272 3.88970554,12.8134272 C3.87952342,12.7061411 3.81486486,12.6116386 3.71855547,12.5632807 C3.62224608,12.5149228 3.50783281,12.5195117 3.41570554,12.5754272 C3.12205489,12.754175 2.850801,12.967351 2.60770554,13.2104272 C1.48170554,14.3374272 1.30770554,16.5974272 1.30070554,16.6934272 C1.2927912,16.8115093 1.35194158,16.924011 1.45370554,16.9844272 C1.50170554,17.0144272 1.67370554,17.1124272 1.87570554,17.1794272 C1.77970554,17.5284272 1.59570554,17.9324272 1.51570554,18.0914272 C1.45478882,18.2122823 1.47816279,18.3585711 1.57370554,18.4544272" />
    <path d="M12.1623774,4.08930403 C13.1888404,3.06392661 14.8519145,3.06392661 15.8783774,4.08930403 C16.9025659,5.11781976 16.9025659,6.7807883 15.8783774,7.80930403 C15.3856844,8.30222549 14.7173121,8.5791651 14.0203774,8.5791651 C13.3234427,8.5791651 12.6550705,8.30222549 12.1623774,7.80930403 C11.138189,6.7807883 11.138189,5.11781976 12.1623774,4.08930403 M0.466377439,10.183304 C0.542889325,10.2600631 0.633114702,10.3217962 0.732377439,10.365304 L4.51037744,12.024304 C4.52837744,13.033304 4.83337744,13.896304 5.45837744,14.522304 C6.08837744,15.153304 6.96237744,15.459304 7.98337744,15.472304 L9.66237744,19.307304 C9.77774009,19.5697052 10.0148224,19.7585484 10.2963774,19.812304 C10.5785038,19.8653855 10.8686271,19.7755409 11.0713774,19.572304 L14.5283774,16.111304 C15.4857525,15.1496188 15.5758371,13.6250431 14.7383774,12.557304 C15.2866662,12.1155018 15.8107587,11.6444861 16.3083774,11.146304 C20.1683774,7.27930403 19.7893774,0.759304027 19.7693774,0.484304027 L19.7533774,0.209304027 L19.4783774,0.190304027 C19.2033774,0.173304027 12.6913774,-0.206695973 8.83037744,3.65930403 C8.33003081,4.16015874 7.8570129,4.68758873 7.41337744,5.23930403 C6.34803224,4.44732045 4.86335796,4.5549487 3.92337744,5.49230403 L0.466377439,8.95430403 C0.126865099,9.29401828 0.126865099,9.84458978 0.466377439,10.184304" />
  </g>
)
