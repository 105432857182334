/** @format */

import * as React from 'react'
import styled, { keyframes } from 'fe-styled-components'
import { useTheme } from 'fe-hoc-theme'

const growBounce = keyframes`
  100% {
    transform: rotateZ(364deg);
  }
`

const LoaderWrapper = useTheme(styled.div`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  line-height: 0;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  > div {
    position: relative;
    animation: ${growBounce} .5s linear infinite;
    
    div {
    box-sizing: border-box;
    border: ${p => Math.floor(p.size / 8)}px solid;
    border-color: transparent rgba(0,0,0,.1) rgba(0,0,0,.3) rgba(0,0,0,.5);
    display: inline-block;
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    border-radius: 50%;
    
    & + div {
    
      border-color: rgba(0,0,0,0) rgba(0,0,0,.2) rgba(0,0,0,.6) rgba(0,0,0,1);
      position: absolute;
      top: 0;
      left: 0;
      transform: rotateZ(45deg);
    }
  }
`)


const defaultProps = {
  size: 30,
  hAlign: true,
  vAlign: true,
}

export const SpinningCircle = props => <LoaderWrapper {...defaultProps} {...props}><div><div/><div/></div></LoaderWrapper>
