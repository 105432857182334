/** @format */

import { getIn } from './getIn'

export const challengeEventType = (type, handlers) => {
  if (null == type) throw new Error('\n\nMissing Event Type')
  const eventType = type.split(/[/]/)
  const handler = getIn(eventType, handlers)
  if (handler == null) throw new Error(`\n\nInvalid handler: ${type}`)
  return handler
}
