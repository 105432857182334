/** @format */

export const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null
}

export const transparent = 'transparent'
export const inherit = 'inherit'
export const currentColor = 'currentColor'

export const white = '#fff'
export const black = '#000'

export const grey_95 = '#f0f2f2'
export const grey_90 = '#e3e7e8'
export const grey_85 = '#d9ddde'
export const grey_80 = '#cdd2d4'
export const grey_60 = '#959a9c'
export const grey_40 = '#5d6366'
export const grey_20 = '#2f3638'
export const grey_10 = '#171b1c'

export const blue_50 = '#def4fd'
export const blue_100 = '#baeafc'
export const blue_200 = '#99dff9'
export const blue_300 = '#60cef7'
export const blue_400 = '#41beec'
export const blue_500 = '#00aeef' // <- Probably the one you want
export const blue_600 = '#219ddf'
export const blue_700 = '#1c8bc7'
export const blue_800 = '#187aad'
export const blue_900 = '#0f5a80'

export const green_50 = '#f0f8f4'
export const green_100 = '#dcecbd'
export const green_200 = '#c7e395'
export const green_300 = '#b1d767'
export const green_400 = '#a0ce40'
export const green_500 = '#8dc63f' // <- Probably the one you want
export const green_600 = '#84b500'
export const green_700 = '#75a106'
export const green_800 = '#678d06'
export const green_900 = '#4a6708'

export const orange_500 = '#ffc600'
export const red_500 = '#e60000'

export const anchor_blue = '#0078a4'

export const info_copy = blue_500
export const info_fill = blue_50
export const info_keyline = '#b3e7fb'

export const info_copy_dark = '#b3e7fb'
export const info_fill_dark = blue_900
export const info_keyline_dark = blue_500

export const error_copy = red_500
export const error_fill = '#fbe5e5'
export const error_keyline = '#f9bfbf'

export const error_copy_dark = '#f9bfbf'
export const error_fill_dark = '#800000'
export const error_keyline_dark = red_500

export const warning_copy = '#f4b21e'
export const warning_fill = '#fff1c0'
export const warning_keyline = '#ffe380'

export const warning_copy_dark = '#ffe380'
export const warning_fill_dark = '#926607'
export const warning_keyline_dark = '#f4b21e'

export const success_copy = '#7aaf2c'
export const success_fill = '#eef7e2'
export const success_keyline = '#c0df95'

export const success_copy_dark = '#c0df95'
export const success_fill_dark = green_900
export const success_keyline_dark = '#7aaf2c'

export const twitter_copy = white
export const twitter_fill = '#55acee'
export const twitter_keyline = twitter_fill

export const facebook_copy = white
export const facebook_fill = '#3b5998'
export const facebook_keyline = facebook_fill

export const google_copy = white
export const google_fill = '#df4a32'
export const google_keyline = google_fill

export const linkedin_copy = white
export const linkedin_fill = '#1c87bd'
export const linkedin_keyline = linkedin_fill

export const youtube_copy = white
export const youtube_fill = '#cd201f'
export const youtube_keyline = youtube_fill

export const instagram_copy = white
export const instagram_fill = '#d93175'
export const instagram_keyline = instagram_fill

export const flickr_copy = white
export const flickr_fill = '#1057ae'
export const flickr_keyline = flickr_fill

export const tumblr_copy = white
export const tumblr_fill = '#35465c'
export const tumblr_keyline = tumblr_fill

export const pinterest_copy = white
export const pinterest_fill = '#bd081c'
export const pinterest_keyline = pinterest_fill

// Teal -> Blue
export const gradient_primary = `linear-gradient(135deg, #2cd5c4, ${blue_500})`
// Blue -> Teal
export const gradient_primary_inverse = `linear-gradient(135deg, ${blue_500}, #2cd5c4)`
// Purple -> Blue
export const gradient_secondary = `linear-gradient(135deg, #7f4d8f, ${blue_500})`
// Green -> Teal
export const gradient_tertiary = `linear-gradient(120deg ,#8BC832, #2CD5C4)`

export const box_shadow_100 = (colour) => `0 4px 8px 0 rgba(${hexToRgb(colour)}, .32)`
export const box_shadow_200 = (colour) => `0 8px 12px 0 rgba(${hexToRgb(colour)}, .25)`
export const box_shadow_300 = (colour) => `0 16px 24px 0 rgba(${hexToRgb(colour)}, .20)`
export const box_shadow_400 = (colour) => `0 24px 24px 0 rgba(${hexToRgb(colour)}, .25)`
export const box_shadow_500 = (colour) => `0 32px 20px -16px rgba(${hexToRgb(colour)}, .5)`

export const cfk = (copy, fill, keyline) => ({ copy, fill, keyline })

export const socialMediaColors = {
  twitter: cfk(twitter_copy, twitter_fill, twitter_keyline),
  facebook: cfk(facebook_copy, facebook_fill, facebook_keyline),
  google: cfk(google_copy, google_fill, google_keyline),
  linkedin: cfk(linkedin_copy, linkedin_fill, linkedin_keyline),
  youtube: cfk(youtube_copy, youtube_fill, youtube_keyline),
  instagram: cfk(instagram_copy, instagram_fill, instagram_keyline),
  flickr: cfk(flickr_copy, flickr_fill, flickr_keyline),
  tumblr: cfk(tumblr_copy, tumblr_fill, tumblr_keyline),
  pinterest: cfk(pinterest_copy, pinterest_fill, pinterest_keyline),
}
