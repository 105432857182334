/** @format */

import { typography } from './base/index'
import {
  cfk,
  transparent,
  white,
  black,
  grey_10,
  grey_20,
  grey_40,
  grey_60,
  grey_80,
  grey_85,
  grey_95,
  blue_100,
  blue_400,
  blue_500,
  blue_600,
  blue_700,
  blue_800,
  blue_900,
  green_100,
  green_500,
  green_700,
  anchor_blue,
  socialMediaColors,
  info_keyline_dark,
  error_fill_dark,
  warning_copy_dark,
  success_copy_dark,
  success_keyline_dark,
  warning_fill_dark,
  error_copy_dark,
  info_copy_dark,
  info_fill_dark,
  warning_keyline_dark,
  error_keyline_dark,
  success_fill_dark,
  gradient_secondary,
  box_shadow_100,
  box_shadow_200,
  box_shadow_300,
  box_shadow_400,
  box_shadow_500,
} from './base/colors'

export const DARK = 'DARK'

export const dark = {
  typography,
  colors: {
    ...socialMediaColors,
    body: cfk(grey_80, grey_10, grey_20),
    active: cfk(grey_80, grey_40, grey_20),
    altActive: cfk(grey_80, blue_900, blue_800),
    mute: cfk(grey_60, transparent, transparent),
    accent: cfk(grey_80, blue_600, blue_700),
    alt: cfk(grey_80, grey_20, grey_40),
    contrast: cfk(grey_10, grey_80, grey_20),
    gradient: {
      primary: cfk(white, gradient_secondary, grey_80),
    },
    shadow: {
      box_shadow_100: box_shadow_100(black),
      box_shadow_200: box_shadow_200(black),
      box_shadow_300: box_shadow_300(black),
      box_shadow_400: box_shadow_400(black),
      box_shadow_500: box_shadow_500(black),
    },
    button: {
      primary: {
        ...cfk(white, blue_500, blue_500),
        hover: cfk(white, blue_700, blue_700),
        focus: cfk(white, blue_700, blue_700),
        active: cfk(white, blue_700, blue_700),
        disabled: cfk(white, blue_100, blue_100),
      },
      standard: {
        ...cfk(grey_80, grey_20, grey_40),
        hover: cfk(grey_80, grey_20, grey_80),
        focus: cfk(grey_80, grey_20, blue_500),
        active: cfk(blue_500, grey_20, blue_500),
        disabled: cfk(grey_40, grey_20, grey_40),
      },
      secondary: {
        ...cfk(anchor_blue, transparent, transparent),
        hover: cfk(anchor_blue, transparent, grey_80),
        focus: cfk(anchor_blue, transparent, blue_500),
        active: cfk(blue_500, transparent, blue_500),
        disabled: cfk(blue_900, transparent, transparent),
      },
      cta: {
        ...cfk(white, green_500, green_500),
        hover: cfk(white, green_700, green_700),
        focus: cfk(white, green_700, green_700),
        active: cfk(white, green_700, green_700),
        disabled: cfk(white, green_100, green_100),
      },
      icon: {
        ...cfk(grey_80, transparent, transparent),
        hover: cfk(grey_80, transparent, grey_80),
        focus: cfk(grey_80, transparent, blue_500),
        active: cfk(blue_500, transparent, blue_500),
        disabled: cfk(grey_40, transparent, transparent),
      },
    },
    input: {
      active: cfk(grey_80, grey_10, blue_500),
      error: cfk(error_copy_dark, error_fill_dark, error_fill_dark),
      checkbox: {
        ...cfk(grey_80, grey_10, grey_60),
      },
      text: {
        ...cfk(grey_20, grey_80, grey_95),
      },
    },
    info: cfk(info_copy_dark, info_fill_dark, info_keyline_dark),
    error: cfk(error_copy_dark, error_fill_dark, error_keyline_dark),
    warning: cfk(warning_copy_dark, warning_fill_dark, warning_keyline_dark),
    success: cfk(success_copy_dark, success_fill_dark, success_keyline_dark),
    timestamp: grey_40,
    anchor: blue_400,
    overlay: {
      ...cfk(grey_95, grey_20, grey_40),
      alt: cfk(white, grey_40, grey_60), // hover
      altActive: cfk(white, grey_40, grey_80), // focus
      active: cfk(grey_80, grey_40, grey_20),
      mute: cfk(grey_85, transparent, transparent),
      accent: cfk(grey_85, black, grey_40),
      altAccent: cfk(grey_85, blue_600, blue_700),
      contrast: cfk(grey_10, grey_80, grey_20),
    },
  },
}
