/** @format */

import { isIgnoredEventType } from './ignoredEventTypes'
import { challengeEventType } from './challengeEventType'
import { logTransducerError } from './logTransducerError'

export const createReducer = (namespace, handlers) => (
  state = {},
  event = {},
) => {
  const { type, payload = [] } = event
  var nextState = state
  try {
    if (isIgnoredEventType(type)) {
      return state
    }

    nextState = challengeEventType(type, handlers).call(null, state, ...payload)
  } catch (error) {
    logTransducerError(namespace, error, event)
  }
  return nextState
}
