/* eslint-disable no-console */

export const deprecateFn = (fn, deprecationMessage = 'This API is deprecated') => function() {
  if (process.env.NODE_ENV !== 'production') {
    if (typeof console !== 'undefined') {
      console.warn(deprecationMessage)
    }
  }

  return fn.apply(this, arguments)
}
