/** @format */

import * as React from 'react'

export const ArrowDownCloud = () => (
  <path d="M16.25,8.25 C16.25,8.25 16.25,8.125 16.25,8.125 C16.25,4.625 13.5,1.875 10,1.875 C6.875,1.875 4.25,4.125 3.875,7.25 C1.625,8 0,10 0,12.5 C0,15.625 2.5,18.125 5.625,18.125 C7.75,18.125 13,18.125 15,18.125 C17.75,18.125 20,15.875 20,13.125 C20,10.75 18.375,8.875 16.25,8.25 Z M10,15.625 L5,10.625 L8.75,10.625 L8.75,6.875 L11.25,6.875 L11.25,10.625 L15,10.625 L10,15.625 Z" />
)
export const ArrowUpCloud = () => (
  <path d="M16.25,8.25 C16.25,8.25 16.25,8.125 16.25,8.125 C16.25,4.625 13.5,1.875 10,1.875 C6.875,1.875 4.25,4.125 3.875,7.25 C1.625,8 0,10 0,12.5 C0,15.625 2.5,18.125 5.625,18.125 C7.75,18.125 13,18.125 15,18.125 C17.75,18.125 20,15.875 20,13.125 C20,10.75 18.375,8.875 16.25,8.25 Z M11.25,11.875 L11.25,15.625 L8.75,15.625 L8.75,11.875 L5,11.875 L10,6.875 L15,11.875 L11.25,11.875 Z" />
)
export const ArrowUpCircle = () => (
  <path d="M0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 C4.5,0 0,4.5 0,10 Z M15,10 L11.25,10 L11.25,15 L8.75,15 L8.75,10 L5,10 L10,5 L15,10 Z" />
)
export const ChatBox = () => (
  <path d="M1.25,0 L18.75,0 C19.44,0 20,0.56 20,1.25 L20,13.75 C20,14.44 19.44,15 18.75,15 L12.5,15 L5,20 L5,15 L1.25,15 C0.56,15 0,14.44 0,13.75 L0,1.25 C0,0.56 0.56,0 1.25,0 Z" />
)
export const ChatBoxDisabled = () => (
  <path d="M18.75,0 C19.44,0 20,0.56 20,1.25 L20,13.75 C20,14.44 19.44,15 18.75,15 L12.5,15 L5,20 L5,15 L1.25,15 C0.56,15 0,14.44 0,13.75 L0,1.25 C0,0.56 0.56,0 1.25,0 L18.75,0 Z M12.25,3.75 L10,6 L7.75,3.75 L6.25,5.25 L8.5,7.5 L6.25,9.75 L7.75,11.25 L10,9 L12.25,11.25 L13.75,9.75 L11.5,7.5 L13.75,5.25 L12.25,3.75 Z" />
)
export const ChatRoundOutline = () => (
  <path d="M10,0 C4.5,0 0,3.875 0,8.75 C0,13.625 4.5,17.5 10,17.5 C10.25,17.5 10.5,17.5 10.75,17.5 L17.5,20 L17.5,14.5 C19,13 20,11 20,8.75 C20,3.875 15.5,0 10,0 Z M15,13.5 L15,16.375 L11.125,15 C11.125,15 10.25,15 10,15 C5.875,15 2.5,12.25 2.5,8.75 C2.5,5.25 5.875,2.5 10,2.5 C14.125,2.5 17.5,5.25 17.5,8.75 C17.5,11.5 15,13.5 15,13.5 Z" />
)
export const Check = () => <polygon points="7 12 3 8 0 11 7 18 20 5 17 2" />
export const CheckLight = () => (
  <path d="M17.875,2.875 L6.25,14.5 L2.125,10.375 C1.625,9.875 0.875,9.875 0.375,10.375 C-0.125,10.875 -0.125,11.625 0.375,12.125 L5.375,17.125 C5.625,17.375 5.875,17.5 6.25,17.5 C6.625,17.5 6.875,17.375 7.125,17.125 L19.625,4.625 C20.125,4.125 20.125,3.375 19.625,2.875 C19.125,2.375 18.375,2.375 17.875,2.875 Z" />
)
export const CheckCircle = () => (
  <path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M8.75,14.25 L4.5,10 L6.25,8.25 L8.75,10.75 L13.75,5.75 L15.5,7.5 L8.75,14.25 Z" />
)
export const CheckCircleOutline = () => (
  <path d="M8.75,14.2675 L4.4825,10 L6.25,8.2325 L8.75,10.7325 L13.75,5.7325 L15.5175,7.5 L8.75,14.2675 Z M10,0 C4.48625,0 0,4.48625 0,10 C0,15.51375 4.48625,20 10,20 C15.51375,20 20,15.51375 20,10 C20,4.48625 15.51375,0 10,0 Z M10,17.5 C5.86375,17.5 2.5,14.13625 2.5,10 C2.5,5.86375 5.86375,2.5 10,2.5 C14.13625,2.5 17.5,5.86375 17.5,10 C17.5,14.13625 14.13625,17.5 10,17.5 Z" />
)
export const CheckSmall = () => (
  <polygon points="15.5 7.5 13.75 5.75 8.75 10.75 6.25 8.25 4.5 10 8.75 14.25" />
)
export const DenyCircle = () => (
  <path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,2.5 C11.625,2.5 13.125,3 14.375,3.875 L3.875,14.375 C3,13.125 2.5,11.625 2.5,10 C2.5,5.875 5.875,2.5 10,2.5 Z M10,17.5 C8.375,17.5 6.875,17 5.625,16.125 L16.125,5.625 C17,6.875 17.5,8.375 17.5,10 C17.5,14.125 14.125,17.5 10,17.5 Z" />
)
export const PlusCircle = () => (
  <path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M15,11.25 L11.25,11.25 L11.25,15 L8.75,15 L8.75,11.25 L5,11.25 L5,8.75 L8.75,8.75 L8.75,5 L11.25,5 L11.25,8.75 L15,8.75 L15,11.25 Z" />
)
export const PlusCircleOutline = () => (
  <path d="M11.25,5 L8.75,5 L8.75,8.75 L5,8.75 L5,11.25 L8.75,11.25 L8.75,15 L11.25,15 L11.25,11.25 L15,11.25 L15,8.75 L11.25,8.75 L11.25,5 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,17.5 C5.875,17.5 2.5,14.125 2.5,10 C2.5,5.875 5.875,2.5 10,2.5 C14.125,2.5 17.5,5.875 17.5,10 C17.5,14.125 14.125,17.5 10,17.5 Z" />
)
export const MinusCircleOutline = () => (
  <path d="M5,8.75 L15,8.75 L15,11.25 L5,11.25 L5,8.75 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,17.5 C5.875,17.5 2.5,14.125 2.5,10 C2.5,5.875 5.875,2.5 10,2.5 C14.125,2.5 17.5,5.875 17.5,10 C17.5,14.125 14.125,17.5 10,17.5 Z" />
)
export const X = () => (
  <polygon points="14.8 2 10 6.8 5.2 2 2 5.2 6.8 10 2 14.8 5.2 18 10 13.2 14.8 18 18 14.8 13.2 10 18 5.2" />
)
export const XSmall = () => (
  <polygon points="12.625 5.625 10 8.25 7.375 5.625 5.625 7.375 8.25 10 5.625 12.625 7.375 14.375 10 11.75 12.625 14.375 14.375 12.625 11.75 10 14.375 7.375" />
)
export const XLight = () => (
  <path d="M18.375,1.625 C17.875,1.125 17.125,1.125 16.625,1.625 L10,8.25 L3.375,1.625 C2.875,1.125 2.125,1.125 1.625,1.625 C1.125,2.125 1.125,2.875 1.625,3.375 L8.25,10 L1.625,16.625 C1.125,17.125 1.125,17.875 1.625,18.375 C1.875,18.625 2.125,18.75 2.5,18.75 C2.875,18.75 3.125,18.625 3.375,18.375 L10,11.75 L16.625,18.375 C16.875,18.625 17.25,18.75 17.5,18.75 C17.75,18.75 18.125,18.625 18.375,18.375 C18.875,17.875 18.875,17.125 18.375,16.625 L11.75,10 L18.375,3.375 C18.875,2.875 18.875,2.125 18.375,1.625 Z" />
)
export const XCircleOutline = () => (
  <path d="M12.625,5.625 L10,8.25 L7.375,5.625 L5.625,7.375 L8.25,10 L5.625,12.625 L7.375,14.375 L10,11.75 L12.625,14.375 L14.375,12.625 L11.75,10 L14.375,7.375 L12.625,5.625 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,17.5 C5.875,17.5 2.5,14.125 2.5,10 C2.5,5.875 5.875,2.5 10,2.5 C14.125,2.5 17.5,5.875 17.5,10 C17.5,14.125 14.125,17.5 10,17.5 Z" />
)
export const AlertTriangle = () => (
  <path d="M19.02,16.91125 L11.1025,2.0675 C10.6325,1.185 9.3675,1.185 8.89625,2.0675 L0.98,16.91125 C0.53625,17.74375 1.14,18.75 2.08375,18.75 L17.9175,18.75 C18.86,18.75 19.46375,17.74375 19.02,16.91125 Z M10,16.25 C9.31,16.25 8.75,15.69 8.75,15 C8.75,14.31 9.31,13.75 10,13.75 C10.69,13.75 11.25,14.31 11.25,15 C11.25,15.69 10.69,16.25 10,16.25 Z M11.25,12.5 L8.75,12.5 L8.75,7.5 L11.25,7.5 L11.25,12.5 Z" />
)
export const AlertCircle = () => (
  <path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,15 C9.25,15 8.75,14.5 8.75,13.75 C8.75,13 9.25,12.5 10,12.5 C10.75,12.5 11.25,13 11.25,13.75 C11.25,14.5 10.75,15 10,15 Z M11.25,11.25 L8.75,11.25 L8.75,5 L11.25,5 L11.25,11.25 Z" />
)
export const AlertCircleOutline = () => (
  <path d="M8.75,5 L11.25,5 L11.25,11.25 L8.75,11.25 L8.75,5 Z M10,15 C10.6903559,15 11.25,14.4403559 11.25,13.75 C11.25,13.0596441 10.6903559,12.5 10,12.5 C9.30964406,12.5 8.75,13.0596441 8.75,13.75 C8.75,14.4403559 9.30964406,15 10,15 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,17.5 C5.875,17.5 2.5,14.125 2.5,10 C2.5,5.875 5.875,2.5 10,2.5 C14.125,2.5 17.5,5.875 17.5,10 C17.5,14.125 14.125,17.5 10,17.5 Z" />
)
export const QuestionCircle = () => (
  <path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,16.25 C9.25,16.25 8.75,15.75 8.75,15 C8.75,14.25 9.25,13.75 10,13.75 C10.75,13.75 11.25,14.25 11.25,15 C11.25,15.75 10.75,16.25 10,16.25 Z M11.875,10.5 C11.25,10.875 11.25,11 11.25,11.25 L11.25,12.5 L8.75,12.5 L8.75,11.25 C8.75,9.625 9.75,8.875 10.5,8.375 C11.125,8 11.25,7.875 11.25,7.5 C11.25,6.75 10.75,6.25 10,6.25 C9.5,6.25 9.125,6.5 8.875,6.875 L8.25,8 L6.125,6.75 L6.75,5.625 C7.375,4.5 8.625,3.75 10,3.75 C12.125,3.75 13.75,5.375 13.75,7.5 C13.75,9.25 12.625,10 11.875,10.5 Z" />
)
export const QuestionCircleOutline = () => (
  <path d="M10,16.25 C9.30964406,16.25 8.75,15.6903559 8.75,15 C8.75,14.3096441 9.30964406,13.75 10,13.75 C10.6903559,13.75 11.25,14.3096441 11.25,15 C11.25,15.6903559 10.6903559,16.25 10,16.25 Z M8.875,6.875 L8.25,8 L6.125,6.75 L6.75,5.625 C7.375,4.5 8.625,3.75 10,3.75 C12.125,3.75 13.75,5.375 13.75,7.5 C13.75,9.25 12.625,10 11.875,10.5 C11.25,10.875 11.25,11 11.25,11.25 L11.25,12.5 L8.75,12.5 L8.75,11.25 C8.75,9.625 9.75,8.875 10.5,8.375 C11.125,8 11.25,7.875 11.25,7.5 C11.25,6.75 10.75,6.25 10,6.25 C9.5,6.25 9.125,6.5 8.875,6.875 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,17.5 C5.875,17.5 2.5,14.125 2.5,10 C2.5,5.875 5.875,2.5 10,2.5 C14.125,2.5 17.5,5.875 17.5,10 C17.5,14.125 14.125,17.5 10,17.5 Z" />
)
export const InfoCircle = () => (
  <path d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M11.25,15 L8.75,15 L8.75,8.75 L11.25,8.75 L11.25,15 Z M10,7.5 C9.25,7.5 8.75,7 8.75,6.25 C8.75,5.5 9.25,5 10,5 C10.75,5 11.25,5.5 11.25,6.25 C11.25,7 10.75,7.5 10,7.5 Z" />
)
export const InfoCircleOutline = () => (
  <path d="M8.75,8.75 L11.25,8.75 L11.25,15 L8.75,15 L8.75,8.75 Z M10,7.5 C9.30964406,7.5 8.75,6.94035594 8.75,6.25 C8.75,5.55964406 9.30964406,5 10,5 C10.6903559,5 11.25,5.55964406 11.25,6.25 C11.25,6.94035594 10.6903559,7.5 10,7.5 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 Z M10,17.5 C5.875,17.5 2.5,14.125 2.5,10 C2.5,5.875 5.875,2.5 10,2.5 C14.125,2.5 17.5,5.875 17.5,10 C17.5,14.125 14.125,17.5 10,17.5 Z" />
)
export const LayoutGrid = () => (
  <path d="M7.5,0 C8.25,0 8.75,0.5 8.75,1.25 L8.75,7.5 C8.75,8.25 8.25,8.75 7.5,8.75 L1.25,8.75 C0.5,8.75 0,8.25 0,7.5 L0,1.25 C0,0.5 0.5,0 1.25,0 L7.5,0 Z M18.75,0 C19.5,0 20,0.5 20,1.25 L20,7.5 C20,8.25 19.5,8.75 18.75,8.75 L12.5,8.75 C11.75,8.75 11.25,8.25 11.25,7.5 L11.25,1.25 C11.25,0.5 11.75,0 12.5,0 L18.75,0 Z M7.5,11.25 C8.25,11.25 8.75,11.75 8.75,12.5 L8.75,18.75 C8.75,19.5 8.25,20 7.5,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,12.5 C0,11.75 0.5,11.25 1.25,11.25 L7.5,11.25 Z M18.75,11.25 C19.5,11.25 20,11.75 20,12.5 L20,18.75 C20,19.5 19.5,20 18.75,20 L12.5,20 C11.75,20 11.25,19.5 11.25,18.75 L11.25,12.5 C11.25,11.75 11.75,11.25 12.5,11.25 L18.75,11.25 Z" />
)
export const ListBullet = () => (
  <path d="M2.5,5 C1.11928813,5 0,3.88071187 0,2.5 C0,1.11928813 1.11928813,0 2.5,0 C3.88071187,0 5,1.11928813 5,2.5 C5,3.88071187 3.88071187,5 2.5,5 Z M2.5,12.5 C1.11928813,12.5 0,11.3807119 0,10 C0,8.61928813 1.11928813,7.5 2.5,7.5 C3.88071187,7.5 5,8.61928813 5,10 C5,11.3807119 3.88071187,12.5 2.5,12.5 Z M2.5,20 C1.11928813,20 0,18.8807119 0,17.5 C0,16.1192881 1.11928813,15 2.5,15 C3.88071187,15 5,16.1192881 5,17.5 C5,18.8807119 3.88071187,20 2.5,20 Z M7.5,1.25 L20,1.25 L20,3.75 L7.5,3.75 L7.5,1.25 Z M7.5,8.75 L20,8.75 L20,11.25 L7.5,11.25 L7.5,8.75 Z M7.5,16.25 L20,16.25 L20,18.75 L7.5,18.75 L7.5,16.25 Z" />
)
export const MenuLines = () => (
  <path d="M0,0.625 L20,0.625 L20,4.375 L0,4.375 L0,0.625 Z M0,8.125 L20,8.125 L20,11.875 L0,11.875 L0,8.125 Z M0,15.625 L20,15.625 L20,19.375 L0,19.375 L0,15.625 Z" />
)
export const Accessibility = () => (
  <path d="M10,5 C8.61928813,5 7.5,3.88071187 7.5,2.5 C7.5,1.11928813 8.61928813,0 10,0 C11.3807119,0 12.5,1.11928813 12.5,2.5 C12.5,3.88071187 11.3807119,5 10,5 Z M17.5,6.25 C18.19,6.25 18.75,6.81 18.75,7.5 C18.75,8.19 18.19,8.75 17.5,8.75 L13.75,8.75 L13.75,18.75 C13.75,19.44 13.19,20 12.5,20 C11.81,20 11.25,19.44 11.25,18.75 L11.25,15 L8.75,15 L8.75,18.75 C8.75,19.44 8.19,20 7.5,20 C6.81,20 6.25,19.44 6.25,18.75 L6.25,8.75 L2.5,8.75 C1.81,8.75 1.25,8.19 1.25,7.5 C1.25,6.81 1.81,6.25 2.5,6.25 L17.5,6.25 Z" />
)
export const Megaphone = () => (
  <path d="M18.375,1.25 L0.875,6.25 C0.375,6.5 0,7 0,7.5 L0,12.5 C0,13 0.375,13.5 0.875,13.75 L2.5,14.125 L2.5,18.75 C2.5,19.5 3,20 3.75,20 L10,20 C10.75,20 11.25,19.5 11.25,18.75 L11.25,16.625 L18.375,18.625 C19.125,18.875 20,18.25 20,17.375 L20,2.5 C20,1.625 19.25,1.125 18.375,1.25 Z M8.75,17.5 L5,17.5 L5,14.875 L8.75,16 L8.75,17.5 Z" />
)
export const Play = () => (
  <path d="M15.6,9.2 L5.6,2.2 C4.9,1.7 4,2.2 4,3 L4,17 C4,17.8 4.9,18.3 5.6,17.8 L15.6,10.8 C16.1,10.4 16.1,9.6 15.6,9.2 Z" />
)
export const Pause = () => (
  <path d="M16,3 C16.6,3 17,3.4 17,4 L17,16 C17,16.6 16.6,17 16,17 L13,17 C12.4,17 12,16.6 12,16 L12,4 C12,3.4 12.4,3 13,3 L16,3 Z M7,3 C7.6,3 8,3.4 8,4 L8,16 C8,16.6 7.6,17 7,17 L4,17 C3.4,17 3,16.6 3,16 L3,4 C3,3.4 3.4,3 4,3 L7,3 Z" />
)

export const CheckShield = () => (
  <path d="M7,0 L0,2.90909091 L0,7.27272727 C0,11.3090909 2.98666667,15.0836364 7,16 C11.0133333,15.0836364 14,11.3090909 14,7.27272727 L14,2.90909091 L7,0 Z M5.44444444,11.6363636 L2.33333333,8.72727273 L3.43,7.70181818 L5.44444444,9.57818182 L10.57,4.78545455 L11.6666667,5.81818182 L5.44444444,11.6363636 Z"/>
)

export const Flame = () => (
  <path d="M12.155,7.86625 C12.155,7.86625 13.0875,2.38125 9.2825,0 C9.16784865,1.90607261 8.24682922,3.67310249 6.75,4.85875 C5.125,6.2875 2.06875,9.5 2.10125,12.925 C2.07743466,15.9115803 3.74914441,18.6533587 6.415,20 C6.50931992,18.6645271 7.13737874,17.4232775 8.1575,16.55625 C9.02188217,15.8914968 9.58305108,14.9074245 9.715,13.825 C11.9906771,15.0347741 13.4624782,17.3521486 13.59,19.92625 L13.59,19.9425 C16.1051795,18.7903824 17.7609307,16.3241438 17.875,13.56 C18.145,10.3425 16.3825,5.9675 14.81875,4.5375 C14.2283688,5.85556055 13.3115545,7.00125566 12.155,7.86625 Z" id="path-1" />
)
