import React from 'react'
import styled from 'fe-styled-components'
import { useTheme } from 'fe-hoc-theme'

const BodyContainer = useTheme(styled.div`
  padding 12px;
  flex: 1 1 auto;
  min-width: 0;
  background: #f2f3f5;
  overflow: hidden;
`)

const TitleArea = useTheme(styled.p`
  font-family: ${p => p.fontFamily};
  font-size: ${p => (p.fontSize ? p.fontSize : '16px')};
  font-weight: 600;
  color: #1d2129;
  line-height: ${p => p.lineHeight};
  text-align: ${p => p.textAlign};
`)

const DescriptionArea = useTheme(styled.p`
  margin: ${p => (p.margin ? p.margin : 0)};
  font-family: ${p => p.fontFamily};
  font-size: 14px;
  font-weight: ${p => p.fontWeight};
  color: #606770;
  line-height: ${p => p.lineHeight};
  background: ${p => p.background};
  text-align: ${p => p.textAlign};
  padding: ${p => p.padding || '0'};
  max-height: 80px;
`)

const SourceArea = useTheme(styled.p`
  margin: ${p => (p.margin ? p.margin : 0)};
  font-family: ${p => p.fontFamily};
  font-size: 12px;
  font-weight: ${p => p.fontWeight};
  color: ${p => p.color};
  text-align: ${p => p.textAlign};
  text-transform: uppercase;
  color: #606770;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`)

const Body = ({url, title, description}) => {
  return (
    <BodyContainer>
      <div>
        <SourceArea>{url}</SourceArea>
        <TitleArea>{title}</TitleArea>
        <DescriptionArea>{description}</DescriptionArea>
      </div>
    </BodyContainer>
  )
}

export default Body
