/** @format */

import * as React from 'react'

export const Ellipsis = () => (
  <path d="M10,12.5 C8.61928813,12.5 7.5,11.3807119 7.5,10 C7.5,8.61928813 8.61928813,7.5 10,7.5 C11.3807119,7.5 12.5,8.61928813 12.5,10 C12.5,11.3807119 11.3807119,12.5 10,12.5 Z M2.5,12.5 C1.11928813,12.5 0,11.3807119 0,10 C0,8.61928813 1.11928813,7.5 2.5,7.5 C3.88071187,7.5 5,8.61928813 5,10 C5,11.3807119 3.88071187,12.5 2.5,12.5 Z M17.5,12.5 C16.1192881,12.5 15,11.3807119 15,10 C15,8.61928813 16.1192881,7.5 17.5,7.5 C18.8807119,7.5 20,8.61928813 20,10 C20,11.3807119 18.8807119,12.5 17.5,12.5 Z" />
)
export const Gps = () => (
  <path d="M19.1113979,5.02380966 L1.66321228,0.0429858853 C0.666173104,-0.206055303 -0.206236176,0.665588857 0.0430236178,1.66175361 L5.0282195,19.0946368 C5.4021092,20.2153222 6.89766796,20.3398428 7.39618755,19.2191574 L10.8858247,11.0007982 L19.1113979,7.51422155 C20.3576969,6.89161858 20.233067,5.39737144 19.1113979,5.02380966 Z" />
)
export const MapMarker = () => (
  <path d="M9.98634945,0 C5.11895476,0 1.25,3.86895476 1.25,8.73634945 C1.25,11.1076443 2.12363495,13.3541342 3.87090484,14.9765991 C3.99570983,15.1014041 8.98790952,19.5943838 9.11271451,19.7191888 C9.61193448,20.0936037 10.3607644,20.0936037 10.7351794,19.7191888 C10.8599844,19.5943838 15.9769891,15.1014041 15.9769891,14.9765991 C17.724259,13.3541342 18.5978939,11.1076443 18.5978939,8.73634945 C18.7226989,3.86895476 14.8537441,0 9.98634945,0 Z M9.98634945,11.2324493 C8.61349454,11.2324493 7.49024961,10.1092044 7.49024961,8.73634945 C7.49024961,7.36349454 8.61349454,6.24024961 9.98634945,6.24024961 C11.3592044,6.24024961 12.4824493,7.36349454 12.4824493,8.73634945 C12.4824493,10.1092044 11.3592044,11.2324493 9.98634945,11.2324493 Z" />
)
export const CurrencyDollar = () => (
  <path d="M11.25,9 L11.25,5.125 C12,5.375 12.5,6.125 12.5,6.875 C12.5,7.625 13,8.125 13.75,8.125 C14.5,8.125 15,7.625 15,6.875 C15,4.625 13.375,2.875 11.25,2.625 L11.25,1.25 C11.25,0.5 10.75,0 10,0 C9.25,0 8.75,0.5 8.75,1.25 L8.75,2.625 C6.625,2.875 5,4.625 5,6.875 C5,9.5 7,10.5 8.75,11 L8.75,14.875 C8,14.625 7.5,13.875 7.5,13.125 C7.5,12.375 7,11.875 6.25,11.875 C5.5,11.875 5,12.375 5,13.125 C5,15.375 6.625,17.125 8.75,17.375 L8.75,18.75 C8.75,19.5 9.25,20 10,20 C10.75,20 11.25,19.5 11.25,18.75 L11.25,17.375 C13.375,17.125 15,15.25 15,13.125 C15,10.5 13,9.5 11.25,9 Z M7.5,6.875 C7.5,6 8,5.375 8.75,5.125 L8.75,8.375 C7.875,8 7.5,7.625 7.5,6.875 Z M11.25,14.875 L11.25,11.625 C12.125,12 12.5,12.375 12.5,13.125 C12.5,14 12,14.625 11.25,14.875 Z" />
)
export const Code = () => (
  <path d="M7.5,19.375 C7.375,19.375 7.25,19.375 7.125,19.25 C6.5,19 6.125,18.375 6.375,17.625 L11.375,2.625 C11.625,2 12.25,1.625 13,1.875 C13.625,2.125 14,2.75 13.75,3.5 L8.75,18.5 C8.5,19 8,19.375 7.5,19.375 Z M15.875,15.25 L14.125,13.5 L17,10.625 L14.125,7.75 L15.875,6 L19.625,9.75 C20.125,10.25 20.125,11 19.625,11.5 L15.875,15.25 Z M4.125,15.25 L0.375,11.5 C-0.125,11 -0.125,10.25 0.375,9.75 L4.125,6 L5.875,7.75 L3,10.625 L5.875,13.5 L4.125,15.25 Z" />
)
export const MusicNotes = () => (
  <path d="M18.125,0 L6.875,0 C6.125,0 5.625,0.5 5.625,1.25 L5.625,12.75 C5.25,12.625 4.875,12.5 4.375,12.5 C2.25,12.5 0.625,14.125 0.625,16.25 C0.625,18.375 2.25,20 4.375,20 C6.5,20 8.125,18.375 8.125,16.25 L8.125,6.25 L16.875,6.25 L16.875,10.25 C16.5,10.125 16.125,10 15.625,10 C13.5,10 11.875,11.625 11.875,13.75 C11.875,15.875 13.5,17.5 15.625,17.5 C17.75,17.5 19.375,15.875 19.375,13.75 L19.375,1.25 C19.375,0.5 18.875,0 18.125,0 Z" />
)
export const Typography = () => (
  <path d="M10,0 L1.25,0 L1.25,5 L2.5,5 L3.4045125,3.1909875 C3.61625,2.7675 4.049075,2.5 4.5225375,2.5 L8.75,2.5 L8.75,16.59905 C8.75,17.1370875 8.4057125,17.6147625 7.8952875,17.7849 L5,18.75 L5,20 L10,20 L15,20 L15,18.75 L12.1047125,17.7849 C11.5942875,17.6147625 11.25,17.1370875 11.25,16.59905 L11.25,2.5 L15.4774625,2.5 C15.950925,2.5 16.3837625,2.7675 16.5955,3.1909875 L17.5,5 L18.75,5 L18.75,0 L10,0 Z" />
)
export const SingleFile = () => (
  <path d="M18.75,18.75 L18.75,6.25 L12.5,0 L2.5,0 C1.75,0 1.25,0.5 1.25,1.25 L1.25,18.75 C1.25,19.5 1.75,20 2.5,20 L17.5,20 C18.25,20 18.75,19.5 18.75,18.75 Z M3.75,2.5 L11.25,2.5 L11.25,7.5 L16.25,7.5 L16.25,17.5 L3.75,17.5 L3.75,2.5 Z" />
)
export const SingleEdit = () => (
  <path d="M18.75,11.25 C19.5,11.25 20,11.75 20,12.5 L20,18.75 C20,19.5 19.5,20 18.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,1.25 C0,0.5 0.5,0 1.25,0 L7.5,0 C8.25,0 8.75,0.5 8.75,1.25 C8.75,2 8.25,2.5 7.5,2.5 L2.5,2.5 L2.5,17.5 L17.5,17.5 L17.5,12.5 C17.5,11.75 18,11.25 18.75,11.25 Z M17.625,0.375 L19.625,2.375 C20.125,2.875 20.125,3.625 19.625,4.125 L11.25,12.5 L7.5,12.5 L7.5,8.75 L15.875,0.375 C16.375,-0.125 17.125,-0.125 17.625,0.375 Z" />
)
export const SingleFileText = () => (
  <path d="M17.5,0 L2.5,0 C1.75,0 1.25,0.5 1.25,1.25 L1.25,18.75 C1.25,19.5 1.75,20 2.5,20 L17.5,20 C18.25,20 18.75,19.5 18.75,18.75 L18.75,1.25 C18.75,0.5 18.25,0 17.5,0 Z M10,16.25 L5,16.25 L5,13.75 L10,13.75 L10,16.25 Z M15,11.25 L5,11.25 L5,8.75 L15,8.75 L15,11.25 Z M15,6.25 L5,6.25 L5,3.75 L15,3.75 L15,6.25 Z" />
)
export const SingleDuplicate = () => (
  <path d="M12.5,5 C13.25,5 13.75,5.5 13.75,6.25 L13.75,18.75 C13.75,19.5 13.25,20 12.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 C1.25,5.5 1.75,5 2.5,5 L12.5,5 Z M17.5,0 C18.25,0 18.75,0.5 18.75,1.25 L18.75,16.25 L16.25,16.25 L16.25,2.5 L5,2.5 L5,0 L17.5,0 Z" />
)
export const SingleCopies = () => (
  <path d="M8.75,10 C9.5,10 10,10.5 10,11.25 L10,18.75 C10,19.5 9.5,20 8.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,11.25 C0,10.5 0.5,10 1.25,10 L8.75,10 Z M13.75,5 C14.5,5 15,5.5 15,6.25 L15,17.5 L12.5,17.5 L12.5,7.5 L2.5,7.5 L2.5,5 L13.75,5 Z M18.75,0 C19.5,0 20,0.5 20,1.25 L20,12.5 L17.5,12.5 L17.5,2.5 L7.5,2.5 L7.5,0 L18.75,0 Z" />
)
export const Website = () => (
  <path d="M18.75,0 C19.5,0 20,0.5 20,1.25 L20,3.75 C20,4.5 19.5,5 18.75,5 L1.25,5 C0.5,5 0,4.5 0,3.75 L0,1.25 C0,0.5 0.5,0 1.25,0 L18.75,0 Z M3.75,7.5 C4.5,7.5 5,8 5,8.75 L5,18.75 C5,19.5 4.5,20 3.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,8.75 C0,8 0.5,7.5 1.25,7.5 L3.75,7.5 Z M18.75,7.5 C19.5,7.5 20,8 20,8.75 L20,18.75 C20,19.5 19.5,20 18.75,20 L8.75,20 C8,20 7.5,19.5 7.5,18.75 L7.5,8.75 C7.5,8 8,7.5 8.75,7.5 L18.75,7.5 Z" />
)
export const Building = () => (
  <path d="M16.5625,0 C17.125,0 17.5,0.333333334 17.5,0.833333334 L17.5,19.1666666 C17.5,19.6666666 17.125,20 16.5625,20 L11.25,20 L11.25,16.25 L8.75,16.25 L8.75,20 L3.4375,20 C2.875,20 2.5,19.6666666 2.5,19.1666666 L2.5,0.833333334 C2.5,0.333333334 2.875,0 3.4375,0 L16.5625,0 Z M6.25,3.75 L6.25,5 L8.75,5 L8.75,3.75 L6.25,3.75 Z M11.25,3.75 L11.25,5 L13.75,5 L13.75,3.75 L11.25,3.75 Z M6.25,6.25 L6.25,7.5 L8.75,7.5 L8.75,6.25 L6.25,6.25 Z M11.25,6.25 L11.25,7.5 L13.75,7.5 L13.75,6.25 L11.25,6.25 Z M6.25,8.75 L6.25,10 L8.75,10 L8.75,8.75 L6.25,8.75 Z M6.25,11.25 L6.25,12.5 L8.75,12.5 L8.75,11.25 L6.25,11.25 Z M11.25,8.75 L11.25,10 L13.75,10 L13.75,8.75 L11.25,8.75 Z M11.25,11.25 L11.25,12.5 L13.75,12.5 L13.75,11.25 L11.25,11.25 Z M18.75,20 L18.75,19.2045455 L18.75,11.25 L19.375,11.25 C19.75,11.25 20,11.5681818 20,12.0454546 L20,19.2045455 C20,19.6818181 19.75,20 19.375,20 L18.75,20 Z M1.25,19.2045455 L1.25,20 L0.625,20 C0.25,20 0,19.6818181 0,19.2045455 L0,12.0454546 C0,11.5681818 0.25,11.25 0.625,11.25 L1.25,11.25 L1.25,19.2045455 Z" />
)
export const Image = () => (
  <path d="M7.5,5 C8.25,5 8.75,5.5 8.75,6.25 C8.75,7 8.25,7.5 7.5,7.5 C6.75,7.5 6.25,7 6.25,6.25 C6.25,5.5 6.75,5 7.5,5 Z M3.75,15 L6.25,10 L8.75,12.5 L12.5,7.5 L16.25,15 L3.75,15 Z M18.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,1.25 C0,0.5 0.5,0 1.25,0 L18.75,0 C19.5,0 20,0.5 20,1.25 L20,18.75 C20,19.5 19.5,20 18.75,20 Z M2.5,17.5 L17.5,17.5 L17.5,2.5 L2.5,2.5 L2.5,17.5 Z" />
)
export const Link = () => (
  <path d="M11.75,6.5 C12.25,6 13,6 13.5,6.5 C14,7 14,7.75 13.5,8.25 L8.25,13.5 C8,13.75 7.625,13.875 7.375,13.875 C7.125,13.875 6.75,13.75 6.5,13.5 C6,13 6,12.25 6.5,11.75 L11.75,6.5 Z M13.75,0 C15.375,0 17,0.625 18.125,1.875 C19.375,3 20,4.625 20,6.25 C20,7.875 19.375,9.5 18.25,10.625 L16.75,12.125 C16.5,12.375 16.125,12.5 15.875,12.5 C15.625,12.5 15.25,12.375 15,12.125 C14.5,11.625 14.5,10.875 15,10.375 L16.375,8.875 C17.125,8.25 17.5,7.25 17.5,6.25 C17.5,5.25 17.125,4.25 16.375,3.5 C15,2.125 12.5,2.125 11.125,3.5 L9.625,5 C9.125,5.5 8.375,5.5 7.875,5 C7.375,4.5 7.375,3.75 7.875,3.25 L9.375,1.875 C10.5,0.625 12.125,0 13.75,0 Z M10.375,15 C10.875,14.5 11.625,14.375 12.125,14.875 C12.625,15.375 12.625,16.125 12.125,16.625 L10.625,18.125 C9.5,19.375 7.875,20 6.25,20 C4.625,20 3,19.375 1.875,18.125 C0.625,17 0,15.375 0,13.75 C0,12.125 0.625,10.5 1.875,9.375 L3.25,7.875 C3.75,7.375 4.5,7.375 5,7.875 C5.5,8.375 5.5,9.125 5,9.625 L3.625,11.125 C2.875,11.75 2.5,12.75 2.5,13.75 C2.5,14.75 2.875,15.75 3.625,16.5 C5,17.875 7.5,17.875 8.875,16.5 L10.375,15 Z" />
)
export const LinkBroken = () => (
  <path d="M11.75,6.5 C12.25,6 13,6 13.5,6.5 C14,7 14,7.75 13.5,8.25 L8.25,13.5 C8,13.75 7.625,13.875 7.375,13.875 C7.125,13.875 6.75,13.75 6.5,13.5 C6,13 6,12.25 6.5,11.75 L11.75,6.5 Z M16.75,12.125 C16.5,12.375 16.125,12.5 15.875,12.5 C15.625,12.5 15.25,12.375 15,12.125 C14.5,11.625 14.5,10.875 15,10.375 L16.375,8.875 C17.125,8.25 17.5,7.25 17.5,6.25 C17.5,5.25 17.125,4.25 16.375,3.5 C15,2.125 12.5,2.125 11.125,3.5 L9.625,5 C9.125,5.5 8.375,5.5 7.875,5 C7.375,4.5 7.375,3.75 7.875,3.25 L9.375,1.875 C10.5,0.625 12.125,0 13.75,0 C15.375,0 17,0.625 18.125,1.875 C19.375,3 20,4.625 20,6.25 C20,7.875 19.375,9.5 18.25,10.625 L16.75,12.125 Z M10.375,15 C10.875,14.5 11.625,14.375 12.125,14.875 C12.625,15.375 12.625,16.125 12.125,16.625 L10.625,18.125 C9.5,19.375 7.875,20 6.25,20 C4.625,20 3,19.375 1.875,18.125 C0.625,17 0,15.375 0,13.75 C0,12.125 0.625,10.5 1.875,9.375 L3.25,7.875 C3.75,7.375 4.5,7.375 5,7.875 C5.5,8.375 5.5,9.125 5,9.625 L3.625,11.125 C2.875,11.75 2.5,12.75 2.5,13.75 C2.5,14.75 2.875,15.75 3.625,16.5 C5,17.875 7.5,17.875 8.875,16.5 L10.375,15 Z M2.875,4.625 L0.375,2.125 C-0.125,1.625 -0.125,0.875 0.375,0.375 C0.875,-0.125 1.625,-0.125 2.125,0.375 L4.625,2.875 C5.125,3.375 5.125,4.125 4.625,4.625 C4.375,4.875 4.125,5 3.75,5 C3.375,5 3.125,4.875 2.875,4.625 Z M17.125,15.375 L19.625,17.875 C20.125,18.375 20.125,19.125 19.625,19.625 C19.375,19.875 19,20 18.75,20 C18.5,20 18.125,19.875 17.875,19.625 L15.375,17.125 C14.875,16.625 14.875,15.875 15.375,15.375 C15.875,14.875 16.625,14.875 17.125,15.375 Z" />
)
export const Email = () => (
  <path d="M9.375,12.375 C9.725,12.55 10.275,12.55 10.625,12.375 L20,7.125 L20,17.5 C20,18.25 19.5,18.75 18.75,18.75 L1.25,18.75 C0.5,18.75 0,18.25 0,17.5 L0,7.125 L9.375,12.375 Z M18.75,1.25 C19.5,1.25 20,1.75 20,2.5 L20,4.375 L10,9.875 L0,4.25 L0,2.5 C0,1.75 0.5,1.25 1.25,1.25 L18.75,1.25 Z" />
)
export const EmailOutline = () => (
  <path d="M18.75,1.25 L1.25,1.25 C0.56,1.25 0,1.80875 0,2.5 L0,17.5 C0,18.19125 0.56,18.75 1.25,18.75 L18.75,18.75 C19.44,18.75 20,18.19125 20,17.5 L20,2.5 C20,1.80875 19.44,1.25 18.75,1.25 Z M17.5,16.25 L2.5,16.25 L2.5,8.40375 L9.38,12.335 C9.765,12.555 10.23625,12.555 10.62,12.335 L17.5,8.40375 L17.5,16.25 Z M17.5,5.525 L10,9.81125 L2.5,5.525 L2.5,3.75 L17.5,3.75 L17.5,5.525 Z" />
)
export const World = () => (
  <path d="M10,0 C15.5,0 20,4.5 20,10 C20,15.5 15.5,20 10,20 C4.5,20 0,15.5 0,10 C0,4.5 4.5,0 10,0 Z M10,17.5 C14.125,17.5 17.5,14.125 17.5,10 C17.5,8.375 17,6.875 16.25,5.625 C15,6 12.75,6 12.5,6 C11.625,6 11.5,5.375 10.875,4.375 C10.5,3.875 9.625,3.125 9.375,2.5 C8,2.625 6.875,3.125 5.875,3.75 C6.75,4.75 7.625,6.625 6.75,7.625 C6,8.625 3.625,9 3.625,10 C3.625,10.75 4.625,11.625 5.25,12.5 C6,13.5 5.875,14.75 5.125,15.75 C6.5,16.875 8.125,17.5 10,17.5 Z M12.75,8.375 C11.25,8.375 9.875,9.375 10,10.75 C10.375,13.875 9.375,15 10.75,15.625 C12.375,16.25 15.625,13.25 15.5,10.75 C15.5,9.375 14.375,8.375 12.75,8.375 Z" />
)
export const WorldOutline = () => (
  <path d="M10,20 C4.5,20 0,15.5 0,10 C0,4.5 4.5,0 10,0 C15.5,0 20,4.5 20,10 C20,15.5 15.5,20 10,20 Z M10,2.5 C5.875,2.5 2.5,5.875 2.5,10 C2.5,14.125 5.875,17.5 10,17.5 C14.125,17.5 17.5,14.125 17.5,10 C17.5,5.875 14.125,2.5 10,2.5 Z M5.25,10 C5.625,9 7.25,8.75 8,9.125 C8.75,9.5 9.375,9.125 10.125,10 C10.75,10.875 11.375,10.625 11.5,11.875 C11.625,13.125 9.875,15.375 9.5,16 C9,16.625 7.75,15.875 7.75,14.125 C7.75,12.375 6.875,12.25 6.375,12.125 C5.75,11.875 4.5,11.75 5.25,10 Z M8.375,4.375 C9.625,3.75 10.5,4.375 11.5,4.375 C12,4.375 12.5,4.375 13,4.5 C14.875,5.625 16.25,7.625 16.25,10 C16.25,10.5 16.125,11 16,11.5 C15.625,11.375 15,10.125 14.25,10.25 C13.375,10.375 13.25,8.75 11.75,9.125 C10.625,9.375 10.125,9 10.25,8.375 C10.375,7.625 11,7 10.5,6.375 C10,5.75 9.875,5.375 8.625,5.125 C8.125,5 8.375,4.375 8.375,4.375 Z" />
)
export const Pencil = () => (
  <path d="M10.125,4.375 L15.625,9.875 L5.875,19.625 C5.625,19.875 5.375,20 5,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,15 C0,14.625 0.125,14.375 0.375,14.125 L10.125,4.375 Z M19.625,4.125 C20.125,4.625 20.125,5.375 19.625,5.875 L17.375,8.125 L11.875,2.625 L14.125,0.375 C14.625,-0.125 15.375,-0.125 15.875,0.375 L19.625,4.125 Z" />
)
export const Disk = () => (
  <path d="M14.625,0.375 C14.375,0.125 14.125,0 13.75,0 L12.5,0 L12.5,3.75 C12.5,4.5 12,5 11.25,5 L5,5 C4.25,5 3.75,4.5 3.75,3.75 L3.75,0 L1.25,0 C0.5,0 0,0.5 0,1.25 L0,18.75 C0,19.5 0.5,20 1.25,20 L18.75,20 C19.5,20 20,19.5 20,18.75 L20,6.25 C20,5.875 19.875,5.625 19.625,5.375 L14.625,0.375 Z M16.25,17.5 L3.75,17.5 L3.75,13.75 C3.75,13 4.25,12.5 5,12.5 L15,12.5 C15.75,12.5 16.25,13 16.25,13.75 L16.25,17.5 Z M8.75,0 L11.25,0 L11.25,3.75 L8.75,3.75 L8.75,0 Z" />
)
export const Printer = () => (
  <path d="M18.75,6.25 L1.25,6.25 C0.5,6.25 0,6.75 0,7.5 L0,15 C0,15.75 0.5,16.25 1.25,16.25 L3.75,16.25 L3.75,18.75 C3.75,19.5 4.25,20 5,20 L15,20 C15.75,20 16.25,19.5 16.25,18.75 L16.25,16.25 L18.75,16.25 C19.5,16.25 20,15.75 20,15 L20,7.5 C20,6.75 19.5,6.25 18.75,6.25 Z M13.75,17.5 L6.25,17.5 L6.25,11.25 L13.75,11.25 L13.75,17.5 Z M3.75,0 L16.25,0 L16.25,3.75 L3.75,3.75 L3.75,0 Z" />
)
export const Award = () => (
  <path d="M16.25,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,1.25 C0,0.5 0.5,0 1.25,0 L16.25,0 L16.25,2.5 L2.5,2.5 L2.5,17.5 L15,17.5 L15,16.25 L17.5,16.25 L17.5,18.75 C17.5,19.5 17,20 16.25,20 Z M20,7.5 C20,8.625 19.5,9.625 18.75,10.25 L18.75,15 L16.25,12.5 L13.75,15 L13.75,10.25 C13,9.625 12.5,8.625 12.5,7.5 C12.5,5.375 14.125,3.75 16.25,3.75 C18.375,3.75 20,5.375 20,7.5 Z M5,6.25 L10,6.25 L10,8.75 L5,8.75 L5,6.25 Z M5,11.25 L11.25,11.25 L11.25,13.75 L5,13.75 L5,11.25 Z" />
)
export const Bell = () => (
  <path d="M18.75,13.75 C19.5,13.75 20,14.25 20,15 C20,15.75 19.5,16.25 18.75,16.25 L1.25,16.25 C0.5,16.25 0,15.75 0,15 C0,14.25 0.5,13.75 1.25,13.75 L1.875,13.75 C2.75,12.875 3.75,11.625 3.75,10 L3.75,6.25 C3.75,2.75 6.5,0 10,0 C13.5,0 16.25,2.75 16.25,6.25 L16.25,10 C16.25,11.625 17.25,12.875 18.125,13.75 L18.75,13.75 Z M12.5,17.5 C12.5,18.875 11.375,20 10,20 C8.625,20 7.5,18.875 7.5,17.5 L12.5,17.5 Z" />
)
export const Bookmark = () => (
  <path d="M5,0 C3.625,0 2.5,1.125 2.5,2.5 L2.5,20 L10,16.25 L17.5,20 L17.5,2.5 C17.5,1.125 16.375,0 15,0 L5,0 Z" />
)
export const Filter = () => (
  <path d="M18.75,0 L1.25,0 C0.5,0 0,0.5 0,1.25 L0,3.75 C0,4.125 0.125,4.5 0.5,4.75 L7.5,10.625 L7.5,18.75 C7.5,19.25 7.75,19.75 8.25,19.875 C8.375,20 8.625,20 8.75,20 C9.125,20 9.375,19.875 9.625,19.625 L12.125,17.125 C12.375,16.875 12.5,16.625 12.5,16.25 L12.5,10.625 L19.5,4.75 C19.875,4.5 20,4.125 20,3.75 L20,1.25 C20,0.5 19.5,0 18.75,0 Z" />
)
export const FlagFinish = () => (
  <path d="M0.625,0 L0.625,20 L3.125,20 L3.125,15 L19.375,15 L19.375,0 L0.625,0 Z M5.625,12.5 L3.125,12.5 L3.125,10 L5.625,10 L5.625,12.5 Z M5.625,5 L3.125,5 L3.125,2.5 L5.625,2.5 L5.625,5 Z M9.375,8.75 L6.875,8.75 L6.875,6.25 L9.375,6.25 L9.375,8.75 Z M13.125,12.5 L10.625,12.5 L10.625,10 L13.125,10 L13.125,12.5 Z M13.125,5 L10.625,5 L10.625,2.5 L13.125,2.5 L13.125,5 Z M16.875,8.75 L14.375,8.75 L14.375,6.25 L16.875,6.25 L16.875,8.75 Z" />
)
export const Lock = () => (
  <path d="M10,0 C7.25,0 5,2.25 5,5 L5,6.875 C3.5,8.25 2.5,10.25 2.5,12.5 C2.5,16.625 5.875,20 10,20 C14.125,20 17.5,16.625 17.5,12.5 C17.5,10.25 16.5,8.25 15,6.875 L15,5 C15,2.25 12.75,0 10,0 Z M11.25,13.375 L11.25,16.25 L8.75,16.25 L8.75,13.375 C8,13 7.5,12.125 7.5,11.25 C7.5,9.875 8.625,8.75 10,8.75 C11.375,8.75 12.5,9.875 12.5,11.25 C12.5,12.125 12,13 11.25,13.375 Z M12.5,5.5 C11.75,5.125 10.875,5 10,5 C9.125,5 8.25,5.125 7.5,5.5 L7.5,5 C7.5,3.625 8.625,2.5 10,2.5 C11.375,2.5 12.5,3.625 12.5,5 L12.5,5.5 Z" />
)
export const LockOpen = () => (
  <path d="M10,2.5 C10.75,2.5 11.375,2.75 11.875,3.375 L12.75,4.25 L14.625,2.625 L13.75,1.625 C12.75,0.625 11.375,0 10,0 C7.25,0 5,2.25 5,5 L5,6.875 C3.5,8.25 2.5,10.25 2.5,12.5 C2.5,16.625 5.875,20 10,20 C14.125,20 17.5,16.625 17.5,12.5 C17.5,8.375 14.125,5 10,5 C9.125,5 8.25,5.125 7.5,5.5 L7.5,5 C7.5,3.625 8.625,2.5 10,2.5 Z M10,8.75 C11.375,8.75 12.5,9.875 12.5,11.25 C12.5,12.125 12,13 11.25,13.375 L11.25,16.25 L8.75,16.25 L8.75,13.375 C8,13 7.5,12.125 7.5,11.25 C7.5,9.875 8.625,8.75 10,8.75 Z" />
)
export const PinOutline = () => (
  <path d="M19.625,7.125 L12.875,0.375 C12.375,-0.125 11.625,-0.125 11.125,0.375 C10.625,0.875 10.625,1.625 11.125,2.125 L11.875,2.875 L3.375,7.5 L2.125,6.25 C1.625,5.75 0.875,5.75 0.375,6.25 C-0.125,6.75 -0.125,7.5 0.375,8 L2.25,9.875 C2.25,9.875 2.25,9.875 2.25,9.875 L5.25,12.875 L0.75,17.5 L2.5,19.25 L7.125,14.625 L12.125,19.625 C12.75,20.25 13.625,19.875 13.875,19.625 C14.375,19.125 14.375,18.375 13.875,17.875 L12.625,16.625 L17.25,8.25 L18,9 C18.75,9.75 19.5,9.25 19.75,9 C20.125,8.5 20.125,7.625 19.625,7.125 Z M10.625,14.75 L5.25,9.375 L13.625,4.75 L15.25,6.375 L10.625,14.75 Z" />
)
export const Home = () => (
  <path d="M17.5,20 C18.25,20 18.75,19.5 18.75,18.75 L18.75,7.5 C18.75,7.125 18.625,6.75 18.25,6.5 L10.75,0.25 C10.25,-0.125 9.625,-0.125 9.125,0.25 L1.625,6.5 C1.375,6.75 1.25,7.125 1.25,7.5 L1.25,18.75 C1.25,19.5 1.75,20 2.5,20 L7.5,20 L7.5,13.75 L12.5,13.75 L12.5,20 L17.5,20 Z" />
)
export const Clock = () => (
  <path d="M10,0 C15.5,0 20,4.5 20,10 C20,15.5 15.5,20 10,20 C4.5,20 0,15.5 0,10 C0,4.5 4.5,0 10,0 Z M10,17.5 C14.125,17.5 17.5,14.125 17.5,10 C17.5,5.875 14.125,2.5 10,2.5 C5.875,2.5 2.5,5.875 2.5,10 C2.5,14.125 5.875,17.5 10,17.5 Z M11.25,5 L11.25,8.75 L15,8.75 L15,11.25 L8.75,11.25 L8.75,5 L11.25,5 Z" />
)
export const Countdown = () => (
  <path d="M15.875,0.375 L19.625,4.125 C20.125,4.625 20.125,5.375 19.625,5.875 C19.375,6.125 19.125,6.25 18.75,6.25 C18.375,6.25 18.125,6.125 17.875,5.875 L16.875,4.875 L15.75,6 C16.875,7.5 17.5,9.25 17.5,11.25 C17.5,16.125 13.625,20 8.75,20 C3.875,20 0,16.125 0,11.25 C0,6.375 3.875,2.5 8.75,2.5 C10.75,2.5 12.5,3.125 14,4.25 L15.125,3.125 L14.125,2.125 C13.625,1.625 13.625,0.875 14.125,0.375 C14.625,-0.125 15.375,-0.125 15.875,0.375 Z M8.75,17.5 C12.25,17.5 15,14.75 15,11.25 C15,7.75 12.25,5 8.75,5 C5.25,5 2.5,7.75 2.5,11.25 C2.5,14.75 5.25,17.5 8.75,17.5 Z M10,7.5 L10,10 L12.5,10 L12.5,12.5 L7.5,12.5 L7.5,7.5 L10,7.5 Z" />
)
export const Hourglass = () => (
  <path d="M18.75,2.5 C19.5,2.5 20,2 20,1.25 C20,0.5 19.5,0 18.75,0 L1.25,0 C0.5,0 0,0.5 0,1.25 C0,2 0.5,2.5 1.25,2.5 L2.5,2.5 C2.625,5.5 3.5,8.125 5,10 C3.5,11.75 2.5,14.5 2.5,17.5 L1.25,17.5 C0.5,17.5 0,18 0,18.75 C0,19.5 0.5,20 1.25,20 L18.75,20 C19.5,20 20,19.5 20,18.75 C20,18 19.5,17.5 18.75,17.5 L17.5,17.5 C17.375,14.375 16.5,11.75 15,10 C16.625,8.125 17.5,5.5 17.5,2.5 L18.75,2.5 Z M12.375,9.125 L11.25,10.125 L12.5,11 C14,12.125 15,14.625 15.125,17.5 L5,17.5 C5.125,14.625 6.125,12.125 7.625,11 L8.875,10.125 L7.625,9.125 C6,7.625 5.125,5.25 5,2.5 L15,2.5 C14.875,5.25 14,7.625 12.375,9.125 Z" />
)
export const Calendar = () => (
  <path d="M18.75,2.5 L16.25,2.5 L16.25,1.25 C16.25,0.5 15.75,0 15,0 C14.25,0 13.75,0.5 13.75,1.25 L13.75,2.5 L11.25,2.5 L11.25,1.25 C11.25,0.5 10.75,0 10,0 C9.25,0 8.75,0.5 8.75,1.25 L8.75,2.5 L6.25,2.5 L6.25,1.25 C6.25,0.5 5.75,0 5,0 C4.25,0 3.75,0.5 3.75,1.25 L3.75,2.5 L1.25,2.5 C0.5,2.5 0,3 0,3.75 L0,18.75 C0,19.5 0.5,20 1.25,20 L18.75,20 C19.5,20 20,19.5 20,18.75 L20,3.75 C20,3 19.5,2.5 18.75,2.5 Z M17.5,17.5 L2.5,17.5 L2.5,8.75 L17.5,8.75 L17.5,17.5 Z" />
)
export const Trash = () => (
  <path d="M15,1.25 L15,3.75 L20,3.75 L20,6.25 L18.75,6.25 L18.75,18.75 C18.75,19.5 18.25,20 17.5,20 L2.5,20 C1.75,20 1.25,19.5 1.25,18.75 L1.25,6.25 L0,6.25 L0,3.75 L5,3.75 L5,1.25 C5,0.5 5.5,0 6.25,0 L13.75,0 C14.5,0 15,0.5 15,1.25 Z M7.5,2.5 L7.5,3.75 L12.5,3.75 L12.5,2.5 L7.5,2.5 Z M16.25,6.25 L3.75,6.25 L3.75,17.5 L16.25,17.5 L16.25,6.25 Z M6.25,8.75 L8.75,8.75 L8.75,16.25 L6.25,16.25 L6.25,8.75 Z M11.25,8.75 L13.75,8.75 L13.75,16.25 L11.25,16.25 L11.25,8.75 Z" />
)
export const ChartBar = () => (
  <path d="M11.25,0 C12,0 12.5,0.5 12.5,1.25 L12.5,18.75 C12.5,19.5 12,20 11.25,20 L8.75,20 C8,20 7.5,19.5 7.5,18.75 L7.5,1.25 C7.5,0.5 8,0 8.75,0 L11.25,0 Z M3.75,12.5 C4.5,12.5 5,13 5,13.75 L5,18.75 C5,19.5 4.5,20 3.75,20 L1.25,20 C0.5,20 0,19.5 0,18.75 L0,13.75 C0,13 0.5,12.5 1.25,12.5 L3.75,12.5 Z M18.75,6.25 C19.5,6.25 20,6.75 20,7.5 L20,18.75 C20,19.5 19.5,20 18.75,20 L16.25,20 C15.5,20 15,19.5 15,18.75 L15,7.5 C15,6.75 15.5,6.25 16.25,6.25 L18.75,6.25 Z" />
)
export const Wand = () => (
  <path d="M10.05695,6.23065 L13.769225,9.942925 L3.7814375,19.9307125 L0.0691625,16.2184375 L10.05695,6.23065 Z M16.2435875,1.9884125 L18.0113375,3.7561625 L15.0061625,6.7613375 L13.2384125,4.9935875 L16.2435875,1.9884125 Z M16.25,8.75 L20,8.75 L20,11.25 L16.25,11.25 L16.25,8.75 Z M15.3033125,13.5420875 L17.9549375,16.1937125 L16.1871875,17.9614625 L13.5355625,15.3098375 L15.3033125,13.5420875 Z M8.75,8.8817842e-16 L11.25,8.8817842e-16 L11.25,3.75 L8.75,3.75 L8.75,8.8817842e-16 Z M3.8128125,2.0382875 L6.4644375,4.6899125 L4.6966875,6.4576625 L2.0450625,3.8060375 L3.8128125,2.0382875 Z" />
)
export const Puzzle = () => (
  <path d="M16.5,8.5 L16.5,3.5 L11.5,3.5 C11.5,2.125 10.375,1 9,1 C7.625,1 6.5,2.125 6.5,3.5 L1.5,3.5 L1.5,8.5 C2.875,8.5 4,9.625 4,11 C4,12.375 2.875,13.5 1.5,13.5 L1.5,18.5 L6.5,18.5 C6.5,17.125 7.625,16 9,16 C10.375,16 11.5,17.125 11.5,18.5 L16.5,18.5 L16.5,13.5 C17.875,13.5 19,12.375 19,11 C19,9.625 17.875,8.5 16.5,8.5 Z" />
)
export const Gear = () => (
  <path d="M16.625,6.5 L18,3.875 L16.25,2.125 L13.625,3.5 C13.25,3.25 12.75,3.125 12.25,3 L11.25,0 L8.75,0 L7.75,2.875 C7.375,3 6.875,3.125 6.5,3.375 L3.875,2 L2,3.875 L3.375,6.5 C3.125,6.875 3,7.375 2.875,7.75 L0,8.75 L0,11.25 L2.875,12.25 C3,12.75 3.25,13.125 3.375,13.625 L2,16.25 L3.75,18 L6.375,16.625 C6.75,16.875 7.25,17 7.75,17.125 L8.75,20 L11.25,20 L12.25,17.125 C12.75,17 13.125,16.75 13.625,16.625 L16.25,18 L18,16.25 L16.625,13.625 C16.875,13.25 17,12.75 17.125,12.25 L20,11.25 L20,8.75 L17.125,7.75 C17,7.375 16.875,6.875 16.625,6.5 Z M10,13.75 C7.875,13.75 6.25,12.125 6.25,10 C6.25,7.875 7.875,6.25 10,6.25 C12.125,6.25 13.75,7.875 13.75,10 C13.75,12.125 12.125,13.75 10,13.75 Z" />
)
export const Camera = () => (
  <path d="M18.75,5 L15.625,5 L13.5,1.75 C13.25,1.5 12.875,1.25 12.5,1.25 L7.5,1.25 C7.125,1.25 6.75,1.5 6.5,1.75 L4.375,5 L1.25,5 C0.5,5 0,5.5 0,6.25 L0,17.5 C0,18.25 0.5,18.75 1.25,18.75 L18.75,18.75 C19.5,18.75 20,18.25 20,17.5 L20,6.25 C20,5.5 19.5,5 18.75,5 Z M10,15 C7.875,15 6.25,13.375 6.25,11.25 C6.25,9.125 7.875,7.5 10,7.5 C12.125,7.5 13.75,9.125 13.75,11.25 C13.75,13.375 12.125,15 10,15 Z" />
)
export const CameraWeb = () => (
  <path d="M18.9426037,16.8548162 L17.0698463,13.7335539 C18.0686502,12.3601985 18.6929027,10.6122917 18.6929027,8.73953431 C18.6929027,3.8703652 14.8225375,0 9.95336838,0 C5.08419926,0 1.21383406,3.8703652 1.21383406,8.73953431 C1.21383406,10.6122917 1.83808651,12.3601985 2.83689044,13.7335539 L0.964133082,16.8548162 C0.589581612,17.6039191 0.589581612,18.3530221 1.08898357,19.102125 C1.58838553,19.7263775 2.33748847,20.1009289 3.08659142,19.9760784 L9.95336838,18.7275735 L16.8201453,19.9760784 C16.9449958,19.9760784 17.0698463,19.9760784 17.1946968,19.9760784 C17.8189493,19.9760784 18.4432017,19.601527 18.8177532,19.102125 C19.4420056,18.4778726 19.4420056,17.6039191 18.9426037,16.8548162 Z M9.9644528,12.5 C7.8394528,12.5 6.2144528,10.875 6.2144528,8.75 C6.2144528,6.625 7.8394528,5 9.9644528,5 C12.0894528,5 13.7144528,6.625 13.7144528,8.75 C13.7144528,10.875 12.0894528,12.5 9.9644528,12.5 Z" />
)
export const CameraVideo = () => (
  <path d="M13.75,7.5 L13.75,3.75 C13.75,3 13.25,2.5 12.5,2.5 L1.25,2.5 C0.5,2.5 0,3 0,3.75 L0,16.25 C0,17 0.5,17.5 1.25,17.5 L12.5,17.5 C13.25,17.5 13.75,17 13.75,16.25 L13.75,12.5 L20,15 L20,5 L13.75,7.5 Z" />
)
export const Magnify = () => (
  <path d="M15.7763975,14.0372671 C16.8944099,12.5465839 17.515528,10.8074534 17.515528,8.81987578 C17.515528,3.97515528 13.6645963,0 8.81987578,0 C3.97515528,0 0,3.97515528 0,8.81987578 C0,13.6645963 3.97515528,17.6397516 8.81987578,17.6397516 C10.8074534,17.6397516 12.6708075,17.0186335 14.0372671,15.9006211 L17.7639752,19.6273292 C18.0124224,19.8757764 18.3850932,20 18.6335404,20 C18.8819876,20 19.2546584,19.8757764 19.5031056,19.6273292 C20,19.1304348 20,18.3850932 19.5031056,17.8881988 L15.7763975,14.0372671 Z M8.81987578,15.0310559 C5.34161491,15.0310559 2.48447205,12.2981366 2.48447205,8.81987578 C2.48447205,5.34161491 5.34161491,2.48447205 8.81987578,2.48447205 C12.2981366,2.48447205 15.1552795,5.34161491 15.1552795,8.81987578 C15.1552795,12.2981366 12.2981366,15.0310559 8.81987578,15.0310559 Z" />
)
export const Templates = () => (
  <path d="M2,4 L0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 L16,18 L2,18 L2,4 Z M18,0 L6,0 C4.9,0 4,0.9 4,2 L4,14 C4,15.1 4.9,16 6,16 L18,16 C19.1,16 20,15.1 20,14 L20,2 C20,0.9 19.1,0 18,0 Z M17,9 L13,9 L13,13 L11,13 L11,9 L7,9 L7,7 L11,7 L11,3 L13,3 L13,7 L17,7 L17,9 Z" />
)

export const Mortar = () => (
  <path d="M18.3333333,8.749375 L20,8.749375 L20,13.749375 L18.3333333,13.749375 L18.3333333,8.749375 Z M11.0341667,12.56 L16.6666667,10.0008333 L16.6666667,14.4516667 C16.6666667,16.64 13.3133333,17.785 10,17.785 C6.68666667,17.785 3.33333333,16.64 3.33333333,14.4516667 L3.33333333,10 L8.96666667,12.5608333 C9.29416667,12.71 9.64166667,12.785 10,12.785 C10.3583333,12.785 10.7058333,12.71 11.0341667,12.56 Z M19.5116667,5.49104167 C19.8091667,5.62604167 20,5.921875 20,6.249375 C20,6.576875 19.8091667,6.87270833 19.5116667,7.00770833 L10.345,11.174375 C10.235,11.224375 10.1175,11.249375 10,11.249375 C9.8825,11.249375 9.765,11.224375 9.655,11.174375 L0.488333333,7.00770833 C0.190833333,6.87270833 0,6.576875 0,6.249375 C0,5.921875 0.190833333,5.62604167 0.489166667,5.49104167 L9.65583333,1.324375 C9.875,1.22520833 10.1258333,1.22520833 10.345,1.324375 L19.5116667,5.49104167 Z" />
)
