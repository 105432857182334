/** @format */

import * as React from 'react'
import styled, { keyframes } from 'fe-styled-components'
import { useTheme } from 'fe-hoc-theme'

const growBounce = keyframes`
  30% {
    transform: scale(1);
    opacity: .75;
  }
  50% {
    transform: scale(.8);
  }
  70% {
    transform: scale(1.2);
    opacity: 1;
  }
  90% {
    transform: scale(1);
    opacity: .75;
  }
`

const LoaderWrapper = useTheme(styled.div`
  display: inline-block;
  width: ${p => p.size}px;
  height: ${p => Math.ceil(p.size * .75)}px;
  line-height: 0;
  
  position: ${p => (p.hAlign || p.vAlign ? 'absolute' : null)};
  left: ${p => (p.hAlign ? '50%' : null)};
  top: ${p => (p.vAlign ? '50%' : null)};
  transform: ${p => (p.hAlign ? 'translateX(-50%)' : null)} ${p => (p.vAlign ? 'translateY(-50%)' : null)};

  > div {
    background-color: ${p => p.fill || p.$theme`colors/alt/copy`};
    display: inline-block;
    width: ${p => Math.floor(p.size / 4)}px;
    height: 100%;
    vertical-align: middle;
    opacity: .75;
    border-radius: ${p => Math.floor(p.size / 12)}px;
    
    animation: 1s ${growBounce} infinite;

      &:nth-child(2) {
        margin: 0 ${p => Math.ceil((p.size / 4) / 2)}px;
        animation-delay: 100ms;
      }
      &:nth-child(3) {
        animation-delay: 200ms;
      }
    }
`)

const defaultProps = {
  size: 26,
  hAlign: true,
  vAlign: true,
}

export const BouncingBars = props => <LoaderWrapper {...defaultProps} {...props}><div/><div/><div/></LoaderWrapper>
