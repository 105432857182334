/** @format */

import * as React from 'react'
import styled, { keyframes } from 'fe-styled-components'
import { useTheme } from 'fe-hoc-theme'

const spinClockwise = keyframes`
  100% {
    transform: rotateZ(364deg);
  }
`

const LoaderWrapper = useTheme(styled.div`
  display: inline-block;
  width: ${p => Math.floor(p.size / 4)}px;
  height: ${p => Math.ceil(p.size * .75)}px;
  line-height: 0;
  
  position: ${p => (p.hAlign || p.vAlign ? 'absolute' : null)};
  left: ${p => (p.hAlign ? '50%' : null)};
  top: ${p => (p.vAlign ? '50%' : null)};
  transform: ${p => (p.hAlign ? 'translateX(-50%)' : null)} ${p => (p.vAlign ? 'translateY(-50%)' : null)};

  > div {
    background-color: ${p => p.fill || p.$theme`colors/alt/copy`};
    display: inline-block;
    width: ${p => Math.floor(p.size / 4)}px;
    height: 100%;
    vertical-align: middle;
    border-radius: ${p => Math.floor(p.size / 12)}px;
    
    animation: 1s ${spinClockwise} infinite;
    }
`)

const defaultProps = {
  size: 26,
  hAlign: true,
  vAlign: true,
}

export const SpinningBar = props => <LoaderWrapper {...defaultProps} {...props}><div/></LoaderWrapper>
