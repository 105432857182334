// @ts-check

import React from 'react'
import { getThemeProvider } from './getThemeProvider'

const { subscribe, getIsBrand2020Active } = getThemeProvider()

export const brand2020Switch = (NewComp, LegacyComp) => {
  class Brand2020Switch extends React.Component {
    componentDidMount() {
      this.mount = true
      this.unsubscribe = subscribe(() => this.mount && this.forceUpdate())
    }

    componentWillUnmount() {
      this.mount = false
      this.unsubscribe()
    }

    render() {
      return getIsBrand2020Active() ? (
        <NewComp {...this.props} />
      ) : (
        <LegacyComp {...this.props} />
      )
    }
  }

  Brand2020Switch.displayName = `Brand2020Switch(${(getDisplayName(NewComp),
  getDisplayName(LegacyComp))})`
  return Brand2020Switch
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
