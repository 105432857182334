import * as React from 'react'

export const BrandPlanBusiness = () => (
  <g>
    <path d="M19.1458376,2 L11.4583756,2 C10.9860238,2 10.6042132,2.447 10.6042132,3 L10.6042132,16 L8.89588828,16 L8.89588828,7 C8.89588828,6.447 8.51407766,6 8.04172584,6 L0.640441895,6 C0.553305675,6 -1.46631038e-12,6.33032227 -3.5299938e-12,7 C-1.06106883e-11,9.29785502 -1.11915549e-11,14.9173584 -2.39115651e-12,17.1975098 C8.41773726e-14,17.8388267 0.555832051,18 0.640441895,18 L19.1458376,18 C19.6181894,18 20,17.553 20,17 L20,3 C20,2.447 19.6181894,2 19.1458376,2 Z M5.47923851,15 L2.91675119,15 L2.91675119,13 L5.47923851,13 L5.47923851,15 Z M5.47923851,11 L2.91675119,11 L2.91675119,9 L5.47923851,9 L5.47923851,11 Z M16.5833502,15 L14.0208629,15 L14.0208629,13 L16.5833502,13 L16.5833502,15 Z M16.5833502,11 L14.0208629,11 L14.0208629,9 L16.5833502,9 L16.5833502,11 Z M16.5833502,7 L14.0208629,7 L14.0208629,5 L16.5833502,5 L16.5833502,7 Z" />
  </g>
)
export const BrandPlanEnterprise = () => (
  <g>
    <path d="M5.83333333,8.33333333 L4.16666667,8.33333333 L4.16666667,0.833333333 C4.16666667,0.3725 4.53916667,6.66133815e-14 5,6.66133815e-14 L15,6.66133815e-14 C15.4608333,6.66133815e-14 15.8333333,0.3725 15.8333333,0.833333333 L15.8333333,5.83333333 L14.1666667,5.83333333 L14.1666667,1.66666667 L5.83333333,1.66666667 L5.83333333,8.33333333 Z" />
    <path d="M19.1666667,6.66666667 L11.6666667,6.66666667 C11.2058333,6.66666667 10.8333333,7.03916667 10.8333333,7.5 L10.8333333,18.3333333 L9.16666667,18.3333333 L9.16666667,10.8333333 C9.16666667,10.3725 8.79416667,10 8.33333333,10 L0.833333333,10 C0.3725,10 0,10.3725 0,10.8333333 L0,19.1666667 C0,19.6275 0.3725,20 0.833333333,20 L19.1666667,20 C19.6275,20 20,19.6275 20,19.1666667 L20,7.5 C20,7.03916667 19.6275,6.66666667 19.1666667,6.66666667 Z M5.83333333,17.5 L3.33333333,17.5 L3.33333333,15.8333333 L5.83333333,15.8333333 L5.83333333,17.5 Z M5.83333333,14.1666667 L3.33333333,14.1666667 L3.33333333,12.5 L5.83333333,12.5 L5.83333333,14.1666667 Z M16.6666667,17.5 L14.1666667,17.5 L14.1666667,15.8333333 L16.6666667,15.8333333 L16.6666667,17.5 Z M16.6666667,14.1666667 L14.1666667,14.1666667 L14.1666667,12.5 L16.6666667,12.5 L16.6666667,14.1666667 Z M16.6666667,10.8333333 L14.1666667,10.8333333 L14.1666667,9.16666667 L16.6666667,9.16666667 L16.6666667,10.8333333 Z" />
  </g>
)
export const BrandPlanFree = () => (
  <g>
    <path d="M10,11.25 C12.7575,11.25 15,8.92125 15,6.05769231 L15,5.19230769 C15,2.32875 12.7575,0 10,0 C7.2425,0 5,2.32875 5,5.19230769 L5,6.05769231 C5,8.92125 7.2425,11.25 10,11.25 Z" />
    <path d="M16.6563636,14.4289287 C12.38,13.5236904 7.62090909,13.5236904 3.34363636,14.4289287 C1.37545455,14.8457691 0,16.2234264 0,17.7803251 L0,20 L20,20 L20,17.7803251 C20,16.2234264 18.6245455,14.8457691 16.6563636,14.4289287 Z" />
  </g>
)
export const BrandPlanProfessional = () => (
  <g>
    <path d="M9.88235294,11.1176471 C7.16470588,11.1176471 4.94117647,8.89411765 4.94117647,6.17647059 L4.94117647,4.94117647 C4.94117647,2.22352941 7.16470588,0 9.88235294,0 C12.6,0 14.8235294,2.22352941 14.8235294,4.94117647 L14.8235294,6.17647059 C14.8235294,8.89411765 12.6,11.1176471 9.88235294,11.1176471 Z" />
    <path d="M11.1176471,16.6764706 C11.1176471,15.6596691 11.4604412,14.7123529 12.0459706,13.9001471 C10.9314265,13.7171691 9.67420588,13.5882353 8.33823529,13.5882353 C5.72188235,13.5882353 3.40107353,14.0808088 1.87702941,14.5093015 C0.750441176,14.8266176 0,15.710625 0,16.7011765 L0,19.7647059 L12.285,19.7647059 C11.5512353,18.8891912 11.1176471,17.8252941 11.1176471,16.6764706 Z" />
    <polygon points="16.3676471 13.5882353 17.4173382 15.6757026 19.7647059 16.0103907 18.0661765 17.6351616 18.4670294 19.9293088 16.3676471 18.845906 14.2682647 19.9293088 14.6691176 17.6351616 12.9705882 16.0103907 15.3179559 15.6757026" />
  </g>
)
export const BrandPlanTeam = () => (
  <g>
    <path d="M10,5 C8.61916667,5 7.5,3.88083333 7.5,2.5 C7.5,1.11916667 8.61916667,0 10,0 C11.3808333,0 12.5,1.11916667 12.5,2.5 C12.5,3.88083333 11.3808333,5 10,5 Z" />
    <path d="M3.33333333,15.8333333 L3.33333333,9.16666667 C3.33333333,8.225 3.65916667,7.365 4.18833333,6.66666667 L1.66666667,6.66666667 C0.745833333,6.66666667 0,7.4125 0,8.33333333 L0,13.3333333 L1.66666667,13.3333333 L1.66666667,17.5 C1.66666667,17.96 2.04,18.3333333 2.5,18.3333333 L4.16666667,18.3333333 C4.62666667,18.3333333 5,17.96 5,17.5 L5,15.8333333 L3.33333333,15.8333333 Z" />
    <path d="M11.6666667,20 L8.33333333,20 C7.87333333,20 7.5,19.6266667 7.5,19.1666667 L7.5,14.1666667 L5,14.1666667 L5,9.16666667 C5,7.78583333 6.11916667,6.66666667 7.5,6.66666667 L12.5,6.66666667 C13.8808333,6.66666667 15,7.78583333 15,9.16666667 L15,14.1666667 L12.5,14.1666667 L12.5,19.1666667 C12.5,19.6266667 12.1266667,20 11.6666667,20 Z" />
    <path d="M3.33333333,5.83333333 C2.4125,5.83333333 1.66666667,5.0875 1.66666667,4.16666667 C1.66666667,3.24583333 2.4125,2.5 3.33333333,2.5 C4.25416667,2.5 5,3.24583333 5,4.16666667 C5,5.0875 4.25416667,5.83333333 3.33333333,5.83333333 Z" />
    <path d="M16.6666667,15.8333333 L16.6666667,9.16666667 C16.6666667,8.225 16.3408333,7.365 15.8116667,6.66666667 L18.3333333,6.66666667 C19.2541667,6.66666667 20,7.4125 20,8.33333333 L20,13.3333333 L18.3333333,13.3333333 L18.3333333,17.5 C18.3333333,17.96 17.96,18.3333333 17.5,18.3333333 L15.8333333,18.3333333 C15.3733333,18.3333333 15,17.96 15,17.5 L15,15.8333333 L16.6666667,15.8333333 Z" />
    <path d="M16.6666667,5.83333333 C17.5875,5.83333333 18.3333333,5.0875 18.3333333,4.16666667 C18.3333333,3.24583333 17.5875,2.5 16.6666667,2.5 C15.7458333,2.5 15,3.24583333 15,4.16666667 C15,5.0875 15.7458333,5.83333333 16.6666667,5.83333333 Z" />
  </g>
)
