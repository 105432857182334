/** @format */

import { DARK, dark } from './dark'
import { LIGHT, light } from './light'

export { DARK, LIGHT }

export const themes = {
  [DARK]: dark,
  [LIGHT]: light,
}
