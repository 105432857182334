/** @format */

import React from 'react'
import ReactDOM from 'react-dom'
import { ContentCuratorApp } from 'fe-content-curator-app'
import axios from 'fe-axios'

function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

// Include CSRF Token for each axios call
axios.interceptors.request.use(
  config => {
    const token = getCookie('__hscsrfid')
    config.headers['Csrf-Token'] = token ? token : null
    return config
  },
  error => Promise.reject(error),
)

function receiveMessage(event) {
  try {
    const json = JSON.parse(event.data)
    if (json.success) {
      ReactDOM.render(<ContentCuratorApp/>, document.getElementById('root'))
    } else {
      window.location.href = '/login'
    }
  } catch(error) {
    // Do nothing for now.
    // This will silence json errors on every message event
  }
}

function ApertureSSO(options) {
  this.domain = options.domain
  this.timeout = options.timeout || 5000
  this.callback = options.callback || receiveMessage
  this.iframe = null
  if (typeof this.callback !== 'function') {
    throw new Error('options.callback must be a function')
  }
}

ApertureSSO.prototype.init = function() {
  this.iframe = window.document.createElement('iframe')
  this.iframe.style.display = 'none'
  this.iframe.src = this.domain
  window.document.body.appendChild(this.iframe)
  window.addEventListener('message', this.callback, false)
}

function checkLogin() {
  new ApertureSSO({
    domain:
      window.location.origin + '/login?response_mode=web_message',
  }).init()
}

checkLogin()
