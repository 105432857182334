/** @format */

const NOT_PROD = process.env.NODE_ENV !== 'production'

export const logTransducerError = (namespace, error, event) => {
  if (NOT_PROD) {
    // eslint-disable-next-line no-console
    console.error(`[${namespace}] Transducer`, `${error}\n\n`, event)
  }
}
