import React from 'react'
import PropTypes from 'fe-prop-types'
import styled from 'fe-styled-components'
import { useTheme } from 'fe-hoc-theme'
import { Button, ICON, SIZE_32 } from 'fe-comp-button'
import { BouncingBars } from 'fe-comp-loader'
import { SvgIcon, XLight, Magnify } from 'fe-comp-svg-icon'


const Input = useTheme(styled.input`
  &&& { /* fight evil dashboard global selectors */
  box-sizing: border-box;
  display: block;
  background-color: ${p => p.$theme`colors/input/text/fill`};
  border: 1px solid ${p =>
    p.hasError
      ? p.$theme`colors/input/error/keyline`
      : p.$theme`colors/input/text/keyline`};
  border-radius: ${p => p.isRound ? '32px' : '4px' };

  width: 100%; /* the parent decides the width */
  height: 100%; /* the parent decides the height */

  padding: 0;
  padding-left: ${p => (p.hasLeftIcon ? '40px' : '8px')};
  padding-right: ${p => (p.isClearable ? '44px' : '8px')};
  font-size: ${p => p.$theme`typography/size/body`};

  &::placeholder {
    font-style: italic;
    font-weight: ${p => p.$theme`typography/weight/normal`};
    font-size: ${p => p.$theme`typography/size/body`};
  }

  &:focus {
    outline: none;
    border: 1px solid ${p => p.$theme`colors/input/text/keyline`};
    box-shadow: 0 0 4px 2px ${p => p.$theme`colors/anchor`};
    font-size: ${p => p.$theme`typography/size/body`};
  }
`)
Input.displayName = 'Input'

const StInputTextCoreRoot = useTheme(styled.div`
  position: relative;
  display: inline-block;
  height: 44px;
`)

function withSvgFillColor(Comp) {
  return useTheme(function(props) {
    return <Comp
      {...props}
      svgFillColor={props.$theme`colors/button/icon/disabled/copy`}
    />
  })
}

const StClearButtonRoot = styled(Button)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`

const ClearButton = withSvgFillColor(({ onClick, svgFillColor }) => {
  return (
    <StClearButtonRoot
      onClick={onClick}
      type={ICON}
      height={SIZE_32}
      width={SIZE_32}
    >
      <SvgIcon
        fill={svgFillColor}
        glyph={XLight}
        size={16}
      />
    </StClearButtonRoot>
  )
})
ClearButton.displayName = 'ClearButton'

const StLeftIconRoot = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  display: flex;
  align-items: center;
`

function LeftIcon({ glyph, isLoading, className = '' }) {
  return (
    <StLeftIconRoot className={className}>
      {isLoading
        ? <BouncingBars size={16} hAlign={false} vAlign={false} />
        : <SvgIcon glyph={glyph} size={16} fill="currentColor" />
      }
    </StLeftIconRoot>
  )
}
LeftIcon.displayName = 'LeftIcon'

export class InputTextCore extends React.Component {
  constructor(props) {
    super(props)

    this.inputRef = React.createRef()
  }
  onClear = () => {
    this.inputRef.current.value = ''
    this.inputRef.current.focus()
    this.props.onChange('')
  }
  onChange = (ev) => {
    this.props.onChange(ev.target.value)
  }
  render() {
    const {
      className,
      isLoading,
      placeholder,
      isRound,
      glyph,
      hasError,
      hasLeftIcon,
      isClearable,
      onChange: _onChange, // eslint-disable-line no-unused-vars
      ...inputProps,
    } = this.props

    const showLeftIcon = isLoading || hasLeftIcon

    return (
      <StInputTextCoreRoot className={className}>
        {showLeftIcon && <LeftIcon glyph={glyph} isLoading={isLoading} />}
        <Input
          innerRef={this.inputRef}
          hasLeftIcon={showLeftIcon}
          isClearable={isClearable}
          hasError={hasError}
          isRound={isRound}
          aria-label={placeholder}
          placeholder={placeholder}
          disabled={Boolean(inputProps.disabled || isLoading)}
          onChange={this.onChange}
          {...inputProps}
        />
        {isClearable && <ClearButton onClick={this.onClear} />}
      </StInputTextCoreRoot>
    )
  }
}

InputTextCore.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  isRound: PropTypes.bool,
  glyph: PropTypes.any,
  hasLeftIcon: PropTypes.bool,
  hasError: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
}

InputTextCore.defaultProps = {
  className: '',
  isLoading: false,
  placeholder: '',
  isRound: false,
  glyph: Magnify,
  hasLeftIcon: false,
  hasError: false,
  isClearable: false,
  onChange: () => {},
}
