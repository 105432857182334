/** @format */

import { typography } from './base/index'
import {
  cfk,
  transparent,
  white,
  grey_20,
  grey_40,
  grey_60,
  grey_80,
  grey_90,
  grey_95,
  blue_50,
  blue_100,
  blue_200,
  blue_500,
  blue_700,
  green_100,
  green_500,
  green_700,
  anchor_blue,
  socialMediaColors,
  info_keyline,
  error_fill,
  warning_copy,
  success_copy,
  success_keyline,
  warning_fill,
  error_copy,
  info_copy,
  info_fill,
  warning_keyline,
  error_keyline,
  success_fill,
  gradient_primary,
  box_shadow_100,
  box_shadow_200,
  box_shadow_300,
  box_shadow_400,
  box_shadow_500,
} from './base/colors'

export const LIGHT = 'LIGHT'

export const light = {
  typography,
  colors: {
    ...socialMediaColors,
    body: cfk(grey_20, white, grey_80),
    active: cfk(grey_20, grey_90, grey_90),
    altActive: cfk(grey_20, blue_50, blue_100),
    mute: cfk(grey_40, transparent, transparent),
    accent: cfk(grey_80, blue_500, blue_700),
    alt: cfk(grey_20, grey_95, grey_80),
    contrast: cfk(white, grey_20, grey_80),
    gradient: {
      primary: cfk(white, gradient_primary, grey_80),
    },
    shadow: {
      box_shadow_100: box_shadow_100(grey_20),
      box_shadow_200: box_shadow_200(grey_20),
      box_shadow_300: box_shadow_300(grey_20),
      box_shadow_400: box_shadow_400(grey_20),
      box_shadow_500: box_shadow_500(grey_20),
    },
    button: {
      primary: {
        ...cfk(white, blue_500, blue_500),
        hover: cfk(white, blue_700, blue_700),
        focus: cfk(white, blue_500, anchor_blue),
        active: cfk(white, blue_700, blue_700),
        disabled: cfk(white, blue_100, blue_100),
      },
      standard: {
        ...cfk(grey_20, white, grey_80),
        hover: cfk(blue_500, white, blue_500),
        focus: cfk(blue_500, white, anchor_blue),
        active: cfk(blue_500, white, blue_500),
        disabled: cfk(grey_60, white, grey_90),
      },
      secondary: {
        ...cfk(anchor_blue, transparent, transparent),
        hover: cfk(anchor_blue, white, grey_80),
        focus: cfk(anchor_blue, white, anchor_blue),
        active: cfk(blue_500, white, blue_500),
        disabled: cfk(blue_200, transparent, transparent),
      },
      cta: {
        ...cfk(white, green_500, green_500),
        hover: cfk(white, green_700, green_700),
        focus: cfk(white, green_500, anchor_blue),
        active: cfk(white, green_700, green_700),
        disabled: cfk(white, green_100, green_100),
      },
      icon: {
        ...cfk(grey_20, transparent, transparent),
        hover: cfk(grey_20, grey_90, grey_90),
        focus: cfk(grey_20, grey_90, anchor_blue),
        active: cfk(grey_20, grey_80, grey_60),
        disabled: cfk(grey_60, transparent, transparent),
      },
    },
    input: {
      active: cfk(grey_20, white, blue_500),
      error: cfk(error_copy, error_fill, error_copy),
      checkbox: {
        ...cfk(grey_20, white, grey_40),
      },
      text: {
        ...cfk(grey_20, white, grey_80),
      },
    },
    info: cfk(info_copy, info_fill, info_keyline),
    error: cfk(error_copy, error_fill, error_keyline),
    warning: cfk(warning_copy, warning_fill, warning_keyline),
    success: cfk(success_copy, success_fill, success_keyline),
    timestamp: grey_40,
    anchor: anchor_blue,
    overlay: {
      ...cfk(grey_20, white, grey_80),
      alt: cfk(grey_20, grey_95, grey_80), // hover
      altActive: cfk(white, grey_40, grey_40), // focus
      active: cfk(grey_20, grey_90, grey_90),
      mute: cfk(grey_40, transparent, transparent),
      accent: cfk(white, grey_20, white),
      altAccent: cfk(grey_80, blue_500, blue_700),
      contrast: cfk(white, grey_20, grey_80),
    },
  },
}
