/** @format */

export const getIn = (cursor = [], obj) => {
  try {
    if (null == obj) return obj
    if (!cursor.length) return obj
    const [current, ...rest] = cursor
    return getIn(rest, obj[current])
  } catch (_err) {
    return undefined
  }
}
