// @ts-check

import React from 'react'
import { getThemeProvider } from './getThemeProvider'

const { getThemeValue, subscribe } = getThemeProvider()

export const withHsTheme = Comp => {
  class WithHsTheme extends React.Component {
    componentDidMount() {
      this.mount = true
      this.unsubscribe = subscribe(() => this.mount && this.forceUpdate())
    }

    componentWillUnmount() {
      this.mount = false
      this.unsubscribe()
    }

    render() {
      return <Comp {...this.props} $theme={getThemeValue} />
    }
  }

  WithHsTheme.displayName = `WithHsTheme(${getDisplayName(Comp)})`
  return WithHsTheme
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
