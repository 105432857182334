// TODO: remove internal store and fallback provider. Should rely only on external provider.
import { subscribe, getState } from './store'
import { getSingleton } from 'fe-lib-singleton'
import { interleave } from 'fe-lib-sequences'

const getIn = (cursor = [], obj) => {
  try {
    if (null == obj) return obj
    if (!cursor.length) return obj
    const [current, ...rest] = cursor
    return getIn(rest, obj[current])
  } catch (_err) {
    return undefined
  }
}

const getThemeValue = (...templateLiteral) => {
  const theme = getState()
  const [parts, ...interops] = templateLiteral
  const cursor = interleave(parts, interops)
    .join('')
    .split(/[/]/)
  return getIn(cursor, theme) || ''
}

// Fallback theme provider uses the internal store
const fallbackProvider = {
  getThemeValue,
  subscribe,
  useBrand2020Theme: () => {},
  useLegacyTheme: () => {},
}

export const themeProviderProxy =
  window &&
  window.hs &&
  window.hs.isFeatureEnabled('FPLAT_33_UI_REFRESH_2020') &&
  getSingleton('fe-lib-theme-provider')
    ? getSingleton('fe-lib-theme-provider')
    : fallbackProvider
