/** @format */
/* eslint-disable no-underscore-dangle */

const root = typeof window === 'undefined' ? {} : window

const safety = () => (root.__doNotUse = root.__doNotUse || {})

export const getSingleton = key => {
  safety()
  return root.__doNotUse[key]
}

export const registerSingleton = (key, value) => {
  safety()
  root.__doNotUse[key] = value
  return getSingleton(key)
}
