/** @format */

import { createStore } from 'fe-lib-store'
import { get, set } from 'fe-lib-localstorage'
import { themes, DARK, LIGHT } from './themes/index'

const NAMESPACE = 'fe-data-theme'
const LS_KEY = 'fe-data-theme.theme'
const LS_THEME = get(LS_KEY, LIGHT)

const initialState = themes[LS_THEME]

const handlers = {
  useTheme: (_store, theme) => themes[set(LS_KEY, theme)] || themes[LIGHT],
}

const { store, dispatch, getState, subscribe } = createStore(
  NAMESPACE,
  handlers,
  initialState,
)
export { store, getState, subscribe }

export const useDarkTheme = () =>
  new Promise(resolve => {
    dispatch('useTheme', DARK)
    resolve(true)
  })

export const useLightTheme = () =>
  new Promise(resolve => {
    dispatch('useTheme', LIGHT)
    resolve(true)
  })
