/** @format */

const ls = typeof window !== 'undefined' && window.localStorage
// TODO: find a better way to do this memberId stuff...
const memberId =
  typeof window !== 'undefined' && window.hs && window.hs.memberId

export const set = (key, value) => {
  if (value != null) {
    ls && ls.setItem(`${memberId || 'MEMBER'}.${key}`, value)
  }

  return value
}
