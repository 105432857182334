import React from 'react'
import styled from 'fe-styled-components'
import { useTheme } from 'fe-hoc-theme'

const ImageContainer = useTheme(styled.div`
  position: relative;
  height: 250px;
  width: 100%;
  flex: 0 0 auto;
`)

const Media = useTheme(styled.div`
  background-size: cover;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-image: ${p => `url("${p.url}")`};
`)

const Image = ({url}) => {
  return (
    <ImageContainer>
      <Media url={url} />
    </ImageContainer>
  )
}

export default Image
